import React, { useContext, useEffect, useRef, useState } from "react";
import PageFrame from "../components/PageFrame";
import { MdFileDownload } from "react-icons/md";
import moment from "moment";
import { Modal } from "antd";
import myContext from "../state/myContext";
import { isTokenExpired } from "../utils/utils";
import { Button, PageLoader } from "../utils/layoutUtils";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";
import VideoThumbnail from "../components/VideoThumbnail";
import { openNotificationWithIcon } from "../App";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Refresh } from "../assets/refresh.svg";
import { ReactComponent as Success } from "../assets/success.svg";
import CustomModal from "../components/CustomModal";
import loader_gif from "../assets/loader1.gif";

const useResponsiveWidth = () => {
  const [width, setWidth] = useState(
    window.innerWidth < 500
      ? window.innerWidth / 4.43
      : window.innerWidth / 7.15
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(
        window.innerWidth < 500
          ? window.innerWidth / 4.43
          : window.innerWidth / 7.15
      );
    };

    window.addEventListener("resize", handleResize);
    // Call handler immediately to set initial width
    handleResize();

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
};
const MyFolders = () => {
  const isMobileView = window.innerWidth < 768; // Change breakpoint if needed
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("token"));
  const dynamicWidth = useResponsiveWidth();
  const videoRef = useRef(null);
  const [open_modal, setOpen_modal] = useState(false);
  const { setShow_login_modal } = useContext(myContext);
  const [isPlaying, setPlaying] = useState(false);
  const [video_url, setVideo_url] = useState({});
  // const { order_list, order_loading } = useSelector((state) => state.user);
  const [theme_name, setTheme_name] = useState("");
  const [down_video, setDown_video] = useState(true);
  const [down_pdf, setDown_pdf] = useState(false);
  const [order_list, setOrder_list] = useState([]);
  const [order_loading, setOrder_loading] = useState(false);
  const [success_popup, setSuccess_popup] = useState(false);

  const startTimeout = () => {
    setOpen_modal(true);
    setPlaying(true);

    setTimeout(() => {
      setPlaying(false);
    }, 5000); // 30,000 milliseconds = 30 seconds
  };

  useEffect(() => {
    if (isTokenExpired()) {
      setShow_login_modal(true);
      return;
    }
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setOrder_loading(true);
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_URL + `get-orders`,
        headers: {
          Auth_ID_Token: user.IdToken,
          Access_Token: user.AccessToken,
        },
      };
      const response = await axios.request(config);
      setOrder_loading(false);
      if (response.status == 200) {
        setOrder_list(response?.data?.body?.items);
      } else {
        setOrder_list([]);
      }
    } catch (error) {
      setOrder_loading(false);
      console.log(error);
    }
  };

  const getvideo_url = async (id) => {
    if (isTokenExpired()) {
      setShow_login_modal(true);
      return;
    }
    startTimeout();

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + `get-order-video?order_id=${id}`,
      headers: {
        Auth_ID_Token: user.IdToken,
        access_token: user.AccessToken,
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.statusCode == "200") {
        setVideo_url(response.data?.body);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!isPlaying && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [isPlaying]);

  const downloadVideo = () => {
    const link = document.createElement("a");
    link.href = video_url?.video_url;
    link.download = `${theme_name || "My_Video"}.mp4`; // You can set the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOpen_modal(false);
    setSuccess_popup(true);
  };

  const downloadPdf = async () => {
    try {
      const response = await fetch(video_url?.pdf_url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${theme_name || "My_theme"}.pdf`; // Specify the default file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up
      setOpen_modal(false);
      setSuccess_popup(true);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  const getStatusClasses = (status) => {
    switch (status) {
      case "SUCCESS":
        return "bg-[#1EB71A] text-white border-[#108E0D]";
      case "IN_QUEUE":
        return "bg-[#FFE0A9] text-black border-[#F5BD58]";
      case "FAILED":
        return "bg-[#c73737] text-white border-[#FF0000]";
      default:
        return "bg-gray-200 text-gray-700 border-gray-300";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "SUCCESS":
        return "Completed";
      case "IN_QUEUE":
        return "In Progress";
      case "FAILED":
        return "Failed";
      default:
        return "Unknown Status";
    }
  };

  return (
    <>
      <PageFrame current_page={"My Order"}>
        {user == null ? (
          <div className="justify-center flex items-center my-4 flex-col gap-4">
            <p className="font-inter font-normal text-[#071524]">
              You are not logged in, Please log in
            </p>
            <button
              onClick={() => setShow_login_modal(true)}
              className={`flex gap-2 bg-transparent h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-clamp-x-slider-button text-clamp-base18 text-[#071524] font-inter font-medium border border-[#FF0000]`}
            >
              <FiLogIn size={20} color="#103935" />
              Log In
            </button>
          </div>
        ) : order_loading ? (
          <PageLoader />
        ) : order_list?.length == 0 && !order_loading ? (
          <div className="items-center flex flex-col gap-4 my-6">
            <p className="font-inter font-normal text-[#071524] text-center ">
              You haven't created any invitation yet please
              <br />
              create you first invitation.
            </p>
            <Button
              title={"Create Invitation"}
              onClick={() => navigate("/")}
              className={"px-11"}
            ></Button>
          </div>
        ) : (
          <div className="my-4 md:m-4 gap-4 flex flex-col">
            <div className="items-center flex flex-col gap-2">
              <button
                onClick={() => fetchOrders()}
                className={`flex gap-2 bg-[#FFF7EA] h-clamp-slider-button rounded-full items-center px-6 text-clamp-base18 text-[#071524] font-inter font-medium border border-[#F5BD58]`}
              >
                <Refresh className="h-10  object-contain" />
                Refresh
              </button>
              <p className="font-inter font-medium text-[#C21E56]">
                Click on refresh button to see updated status
              </p>
            </div>
            {isMobileView ? ( // Mobile Card Layout
              <div className="flex flex-col gap-4 p-4">
                {order_list?.map((item, index) => (
                  <div
                    key={index}
                    className="border p-4 rounded-lg shadow-lg bg-white "
                  >
                    <h2 className="text-lg font-medium text-[#071524]">
                      {item.theme_name}
                    </h2>
                    <div className="flex items-center ">
                      <p className="text-[#071524] flex-1">
                        {moment(item.theme_gen_req_date).format(
                          "DD-MM-YYYY hh:mm a"
                        )}
                      </p>
                    </div>
                    <div className="flex justify-start">
                      {item.restatus === "SUCCESS" ? (
                        <Button
                          className={"mt-2 self-end flex"}
                          title={"Download"}
                          onClick={() =>
                            item.restatus === "SUCCESS" &&
                            getvideo_url(item.request_id)
                          }
                        />
                      ) : (
                        <div
                          className={`py-1 w-[110px] text-center rounded-md mt-2 text-sm font-inter px-4 justify-center items-center border-[1px] ${getStatusClasses(
                            item.restatus
                          )}`}
                        >
                          {getStatusText(item.restatus)}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="overflow-x-auto ">
                <table className="min-w-full bg-white border border-gray-200 font-inter font-normal">
                  <thead>
                    <tr className="w-full bg-gray-100 border-b border-gray-200">
                      <th className="py-2 px-2 text-left text-[#071524] ">#</th>
                      <th className="py-2 px-2 text-left text-[#071524] min-w-[170px]">
                        Theme Name
                      </th>
                      <th className="py-2 px-2 text-left text-[#071524] min-w-[190px]">
                        Date
                      </th>
                      <th className="py-2 px-2 text-left text-[#071524]">
                        Status
                      </th>
                      <th className="py-2 px-2 text-left text-[#071524]">
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order_list?.map((item, index) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td className="py-2 px-2 text-[#071524]">
                          {index + 1}
                        </td>
                        <td className="py-2 px-2 text-[#071524]">
                          {item.theme_name}
                        </td>
                        <td className="py-2 px-2 text-[#071524]">
                          {moment(item.theme_gen_req_date).format(
                            "DD-MM-YYYY hh:mm a"
                          )}
                        </td>
                        <td className=" justify-start flex py-1 px-2">
                          <div
                            className={`py-1 flex w-[150px] gap-2 text-center rounded-full text-lg font-inter px-4 justify-center items-center border-[1px] ${getStatusClasses(
                              item.restatus
                            )}`}
                          >
                            {item.restatus == "IN_QUEUE" && (
                              <div className="w-5 h-5 flex items-center justify-center">
                                <img
                                  src={loader_gif}
                                  alt="Loading GIF"
                                  className="h-[100%] w-[100%] object-contain rounded-full"
                                />
                              </div>
                            )}
                            {getStatusText(item.restatus)}
                          </div>
                          {/* {item.restatus == "SUCCESS" && (
                            <div
                              className={`py-1 w-[110px] text-center rounded-full text-sm font-inter px-4  justify-center items-center border-[1px] bg-[#1EB71A] text-white border-[#1EB71A]`}
                            >
                              Completed
                            </div>
                          )}
                          {item.restatus == "IN_QUEUE" && (
                            <div
                              className={`py-1 rounded-full text-sm font-inter flex px-4 justify-center items-center border-[1px] bg-[#FFE0A9] text-[#07152499] border-[#F5BD58]
                          `}
                            >
                              
                              In Progress
                            </div>
                          )}
                          {item.restatus == "FAILED" && (
                            <div
                              className={`py-1 rounded-full text-sm font-inter flex px-4 justify-center items-center border-[1px] bg-[#D22B2B] text-[#FFF] border-[#880808]`}
                            >
                              Failed
                            </div>
                          )} */}
                        </td>
                        <td className="py-2 px-2 text-[#071524]">
                          <button className="flex justify-start items-center bg-[#C21E56] text-[#FFF] font-inter font-normal rounded-full px-4 py-2 gap-2">
                            <MdFileDownload
                              className={` ${
                                item.restatus == "SUCCESS"
                                  ? "opacity-100 cursor-pointer"
                                  : "opacity-50 cursor-not-allowed"
                              }`}
                              size={20}
                              // color={"#103935"}
                              onClick={() => {
                                setTheme_name(
                                  item?.theme_name?.replace(" ", "-")
                                );
                                item.restatus == "SUCCESS" &&
                                  getvideo_url(item.request_id);
                              }}
                            />
                            Download
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </PageFrame>
      <Modal
        width={window.innerWidth < 500 ? "100%" : "60%"}
        styles={{
          content: {
            backgroundColor: "#fff",
            paddingInline: 0,
            paddingTop: 0,
            paddingBottom: 0,
            overflow: "hidden",
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={open_modal}
        footer={() => (
          <div
            className="flex rounded-b-lg overflow-hidden"
            style={{ height: dynamicWidth }}
          >
            <iframe
              src="/assets/Footer.html"
              title="Animated Content"
              height={dynamicWidth}
              width={"100%"}
              style={{ border: "none", objectFit: "fill" }}
              className="relative"
            />
          </div>
          // <img src={adfooter} className="w-full h-auto object-contain" />
        )}
        centered
        closeIcon={null}
      >
        {isPlaying ? (
          <div className="text-[30px] h-auto  text-[#fff] text-center font-inter ">
            <p className="text-clamp-heading text-[#FF9500] text-center font-inter font-semibold pt-clamp-y-sm">
              Go Green Go Digital
            </p>
            <p className="text-clamp-base18 px-clamp-x-md text-[#071524] text-center font-inter font-normal md:font-medium pt-clamp-y-sm">
              Please wait your Invitation card (PDF+video) Generating, Meanwhile
              <strong> do not</strong> refresh & close the window
            </p>
            <div className="h-full justify-center items-center flex mt-3">
              <video
                ref={videoRef}
                loop
                autoPlay={true}
                className="w-[60%] h-full object-contain rounded-md"
              >
                <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4" />
              </video>
            </div>
          </div>
        ) : (
          <div className="flex gap-2 mt-2 mx-4 flex-col items-center ">
            <div className="flex  items-center justify-between  w-full">
              <div className="font-baskerville text-clamp-popup-title text-[#071524] flex-1">
                Download
              </div>
              <IoMdClose
                size={30}
                color="#071524"
                onClick={() => setOpen_modal(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center gap-4">
              <div className="checkbox-wrapper-65">
                <label htmlFor="cbk1-65-a">
                  <input
                    type="checkbox"
                    id="cbk1-65-a"
                    defaultChecked={down_video}
                    onChange={(v) => setDown_video(v.target.checked)}
                  />
                  <span className="cbx">
                    <svg width="12px" height="11px" viewBox="0 0 12 11">
                      <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                    </svg>
                  </span>
                  <span className="font-inter text-[16px] font-light leading-[20px] text-[#071524]">
                    Video
                  </span>
                </label>
              </div>
              <div className="checkbox-wrapper-65">
                <label htmlFor="cbk1-65">
                  <input
                    type="checkbox"
                    id="cbk1-65"
                    defaultChecked={down_pdf}
                    onChange={(v) => setDown_pdf(v.target.checked)}
                  />
                  <span className="cbx">
                    <svg width="12px" height="11px" viewBox="0 0 12 11">
                      <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                    </svg>
                  </span>
                  <span className="font-inter text-[16px] font-light leading-[20px] text-[##071524]">
                    Card’s PDF
                  </span>
                </label>
              </div>
            </div>
            <div className="relative    overflow-hidden gap-4 flex flex-col">
              <div className="w-[10rem]">
                <VideoThumbnail videoUrl={video_url?.video_url} />
              </div>

              <Button
                title={"Downlod"}
                onClick={() => {
                  if (!down_pdf && !video_url) {
                    openNotificationWithIcon(
                      "info",
                      "Alert",
                      "Please select download options"
                    );
                    return;
                  }
                  down_video && downloadVideo();
                  down_pdf && downloadPdf();
                }}
                className={"w-full h-[50px] text-[18px]"}
              />
            </div>
          </div>
        )}
      </Modal>
      <CustomModal
        isModalOpen={success_popup}
        setIsModalOpen={setSuccess_popup}
        heading={"Alert"}
      >
        <div className="items-center justify-center flex flex-col gap-4">
          <Success className="h-16 object-contain" />
          <p className="font-inter font-normal text-lg text-white">
            Downloaded Successfully
          </p>
        </div>
        {/* <div className="flex justify-end mt-2">
          <Button title={"Done"} />
        </div> */}
      </CustomModal>
    </>
  );
};

export default MyFolders;
