import React, { useContext, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { CognitoIdentityServiceProvider, config } from "aws-sdk";
import { Oval } from "react-loader-spinner";
import { generateSecretHash } from "../../utils/layoutUtils";
import { openNotificationWithIcon } from "../../App";
import {
  emailregex,
  handleError,
  handleForgotPasswordAndConfirmCodeError,
  passregex,
} from "../../utils/utils";

config.update({ region: "ap-south-1" });
const cognito = new CognitoIdentityServiceProvider();

const ForgetPassword = ({ setIsSignUp, setFor_pass }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp_sent, setOtp_sent] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
    cPassword: "",
    otp: "",
  });
  console.log(form);
  const initiateForgotPassword = async () => {
    if (!form.email) {
      openNotificationWithIcon("info", "Alert", "Please enter email address");
      return;
    }
    if (!emailregex.test(form.email)) {
      openNotificationWithIcon("info", "Alert", "Invailid email address");
      return;
    }
    const secretHash = await generateSecretHash(form.email); // Generate the secret hash
    const params = {
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      Username: form.email,
      SecretHash: secretHash, // Include the secret hash
    };

    try {
      setIsSubmitting(true);
      await cognito.forgotPassword(params).promise();
      openNotificationWithIcon(
        "success",
        "Success",
        "Password reset initiated. Check your email for the verification code."
      );
      console.log(
        "Password reset initiated. Check your email for the verification code."
      );
      setOtp_sent(true);
      setIsSubmitting(false);
    } catch (error) {
      handleForgotPasswordAndConfirmCodeError(error);
      setIsSubmitting(false);
    }
  };

  const confirmForgotPassword = async () => {
    if (!form.otp || !form.password || !form.cPassword) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Please enter otp, new password and confirm password"
      );
      return;
    }
    if (!passregex.test(form.password)) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "Password must contain a minimum of 8 characters with at least one number"
      );
      return;
    }
    if (form.password !== form.cPassword) {
      openNotificationWithIcon(
        "info",
        "Alert",
        "The password and confirmation password do not match."
      );

      return;
    }
    const secretHash = await generateSecretHash(form.email); // Generate the secret hash
    const params = {
      ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      ConfirmationCode: form.otp,
      Username: form.email,
      Password: form.password,
      SecretHash: secretHash, // Include the secret hash
    };

    try {
      setIsSubmitting(true);
      await cognito.confirmForgotPassword(params).promise();
      console.log("Password reset successful!");
      openNotificationWithIcon(
        "success",
        "Success",
        "Password reset successful!"
      );
      setIsSignUp(false);
      setFor_pass(false);
      setIsSubmitting(false);
    } catch (error) {
      handleForgotPasswordAndConfirmCodeError(error);
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]:
          name == "otp"
            ? value.trim().replace(/[^0-9 ]/g, "")
            : value.trimStart(),
      };
    });
  };
  return (
    <>
      <CustomInput
        className={"mt-8"}
        placeholder="Enter here"
        label={"Email ID*"}
        value={form.email}
        name={"email"}
        onChange={handleChange}
        type="email"
        readOnly={otp_sent}
      />
      {otp_sent && (
        <p className="text-right font-inter text-[#FFFFFF] text-clamp-sm mt-4">
          <span
            className="text-[#FCD36D] cursor-pointer font-medium"
            onClick={() => initiateForgotPassword()}
          >
            Resend OTP
          </span>{" "}
        </p>
      )}
      {otp_sent && (
        <>
          <CustomInput
            placeholder="Enter here"
            maxLength={6}
            label={"OTP"}
            value={form.otp}
            name={"otp"}
            onChange={handleChange}
            type="text"
          />
          <CustomInput
            isPassword
            placeholder="Enter here"
            label={"New Password *"}
            value={form.password}
            name={"password"}
            onChange={handleChange}
            type="password"
          />
          <CustomInput
            isPassword
            placeholder="Enter here"
            label={"Confirm Password *"}
            value={form.cPassword}
            name={"cPassword"}
            onChange={handleChange}
            type="password"
          />
        </>
      )}

      <button
        onClick={otp_sent ? confirmForgotPassword : initiateForgotPassword}
        disabled={isSubmitting}
        className={`mt-8 flex justify-center border-[1px] border-[#CD8E42] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-[15px] text-clamp-base18 text-[#071524] font-inter font-medium bg-gradient-to-r from-[#FFF090] to-[#FABF55]`}
      >
        {isSubmitting ? (
          <>
            <Oval
              visible={true}
              height="30"
              width="30"
              color="#FF9500"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              secondaryColor="#FF950055"
              wrapperClass=""
              strokeWidth={6}
            />
          </>
        ) : otp_sent ? (
          "Submit"
        ) : (
          "Send OTP to reset password"
        )}
      </button>
      <p className="text-center font-inter text-[#FFFFFF] text-clamp-sm mt-4 md:mt-8">
        <span
          className="text-[#FCD36D] cursor-pointer font-medium"
          onClick={() => {
            setForm({
              email: "",
              password: "",
              fName: "",
              lName: "",
              mName: "",
              cPassword: "",
            });
            setIsSignUp(false);
            setFor_pass(false);
          }}
        >
          Sign In
        </span>{" "}
      </p>
    </>
  );
};

export default ForgetPassword;
