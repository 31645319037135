import React, { useContext, useEffect, useState } from "react";
import { FiHome } from "react-icons/fi";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, PageLoader } from "../../utils/layoutUtils";
import { IoPlayCircleSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { Modal } from "antd";
import { IoArrowBack } from "react-icons/io5";
import myContext from "../../state/myContext";
import play from "../../assets/play.png";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import {
  setEdit_Page,
  setEvent_Page,
  setMy_Theme,
  setSelected_Theme,
  setSnap_shot,
} from "../../redux/slice/userSlice";
import axios from "axios";
import { isTokenExpired, isTokenValid } from "../../utils/utils";

const TemplateDetailPage = () => {
  const [type, setType] = useState(0);
  const navigate = useNavigate();

  const [theme_detail, setTheme_detail] = useState({});
  const { selected_json, theme_preview, save_the_date } = useSelector(
    (state) => state.user
  );

  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { setSelected_theme, setShow_login_modal, setJson_data } =
    useContext(myContext);

  const showPlan = localStorage.getItem("plan") || false;

  const get_theme = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        save_the_date
          ? process.env.REACT_APP_API_URL +
              `get-theme?category=${state.cat}&sub-category=${state.s_cat}&theme=${state.item?.themeID}&save-the-date=Y`
          : process.env.REACT_APP_API_URL +
              `get-theme?category=${state.cat}&sub-category=${state.s_cat}&theme=${state.item?.themeID}`,
        { maxBodyLength: Infinity }
      );

      setLoading(false);
      if (response.status == 200) {
        store.dispatch(setMy_Theme(response.data));
        store.dispatch(setSelected_Theme(response.data));
        // setSelected_theme(response.data?.body);
        // setTheme_detail(response.data?.body);
        // setJson_data(response.data?.body?.theme_json?.pages);
      } else {
        setTheme_detail({});
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // const response = await get_theme_detail(
    //   state.cat,
    //   state.s_cat,
    //   state.item?.themeID
    // );
    // setLoading(false);
    // console.log("respondse", response.data);
    // if (response.status == 200) {
    //   store.dispatch(setMy_Theme(response.data));
    //   store.dispatch(setSelected_Theme(response.data));
    //   setSelected_theme(response.data?.body);
    //   setTheme_detail(response.data?.body);
    //   setJson_data(response.data?.body?.theme_json?.pages);
    // } else {
    //   setTheme_detail({});
    // }
  };

  useEffect(() => {
    get_theme();
  }, []);

  const handleEdit = () => {
    if (!isTokenValid()) {
      setShow_login_modal(true);
      return;
    }
    // if (!showPlan) {
    //   localStorage.setItem("plan", true);
    //   navigate("/plans");
    //   return;
    // }
    store.dispatch(setEdit_Page(0));
    store.dispatch(setEvent_Page(1));
    store.dispatch(setSnap_shot([]));
    navigate("/plans", { state: theme_detail?.theme_json });
    // navigate("/template/" + selected_json?.themeName + "/edit/cover-page", {
    //   state: theme_detail?.theme_json,
    // });
  };

  return (
    <>
      <div className="relative flex justify-center flex-col ">
        <div
          className="flex md:hidden gap-1 items-center mx-4 mt-2"
          onClick={() => navigate(-1)}
        >
          <IoArrowBack size={20} color="#071524" />
          <div className="font-inter text-[16px] text-[#071524]">Back</div>
        </div>
        <div className="flex max-w-[90%] xl:max-w-[80%] flex-col self-center w-full rounded-[10px] overflow-hidden border-[#07152433] border-[1px] mt-3 md:mt-8">
          <div className="bg-[#103935] flex flex-col md:flex-row  gap-3 justify-between items-center p-2 md:py-5 md:px-6">
            <div className="hidden md:flex gap-2 md:gap-3">
              <div className="h-[25px] w-[25px] flex">
                <FiHome size={25} color="#fff" />
              </div>

              <Link className="font-inter text-clamp-lg text-[#fff]" to={`/`}>
                Home
              </Link>
              <div className="font-inter text-clamp-lg text-[#fff]">/</div>
              <div className="font-inter text-clamp-lg text-[#ffffff50]">
                View Details: {state?.item?.themeName} {state.cat} invitation
                video & card
              </div>
            </div>
            <div className="flex md:hidden flex-col gap-2 md:gap-3">
              <div className="font-inter text-clamp-lg text-[#ffffff]">
                {state?.item?.themeName} {state.cat} invitation video & card
              </div>

              <div className="flex items-center justify-between">
                {/* <span className="font-inter text-[#fff] text-[20px] md:text-[30px] xl:text-[36px] font-bold ">
                  {state?.item?.price}
                </span> */}
                {/* <div className="font-inter text-clamp-base16 text-[#fff]">
                  Starts at just{" "}
                  <span className="text-[20px] md:text-[30px] xl:text-[36px] font-bold ">
                    ₹{state?.item?.price}
                  </span>
                </div> */}
                <Button title={"Edit"} onClick={handleEdit} />
              </div>
            </div>
            <>
              {/* <span className="hidden md:block font-inter text-[#fff] text-[36px] font-bold">
                {state?.item?.price}
              </span> */}
              {/* <div className="hidden md:block font-inter text-clamp-base16 text-[#fff]">
                Starts at just{" "}
                <span className="text-[36px] font-bold">
                  ₹{state?.item?.price}
                </span>
              </div> */}
            </>
          </div>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              <div className="flex p-3 md:px-6 md:py-3 items-center justify-between gap-4 break-words ">
                <div className="font-inter text-clamp-lg text-[#071524] flex-1">
                  Customize wedding invitation PDF card and video at just one
                  attempt
                </div>
                <button
                  onClick={handleEdit}
                  className={`hidden md:flex justify-center h-[25px] lg:h-[40px] rounded-full items-center p-[20px] lg:p-[30px] text-lg lg:text-2xl text-[#fff] font-inter font-normal bg-[#ff0000]`}
                >
                  Edit Invitation
                </button>
                {/* <Button
                  className={"hidden md:flex"}
                  title={"Edit Invitation"}
                  onClick={() => {
                    store.dispatch(setEdit_Page(0));
                    store.dispatch(setEvent_Page(1));
                    store.dispatch(setSnap_shot([]));
                    localStorage.getItem("token") == null
                      ? setShow_login_modal(true)
                      : navigate(
                          "/template/" +
                            selected_json?.themeName +
                            "/edit/cover-page"
                        );
                  }}
                /> */}
              </div>
              <div className="hidden md:block font-inter text-[14px] text-[#ff0000] mx-6 text-right">
                Click on edit button to edit card or video*
              </div>

              <div className=" container max-w-full md:max-w-[50%] md:my-4 xl:my-8 hidden xl:flex items-center justify-center self-center">
                <div className="grid  grid-cols-2  gap-3">
                  <div className="relative w-full h-auto rounded-[10px] overflow-hidden">
                    <img
                      src={state.item?.cover_image_url}
                      className="w-full h-full object-fill"
                    />

                    <div className="absolute z-10 inset-0 flex items-center justify-center h-full w-full bg-[#00000055]">
                      <img
                        onClick={() => setType(1)}
                        src={play}
                        className="h-[40px] w-[40px] object-contain cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="w-full h-auto relative rounded-[10px] overflow-hidden">
                    <img
                      src={state.item?.cover_image_url}
                      className="w-full h-full object-fill"
                    />

                    <div className="absolute z-10 inset-0 flex items-center justify-center h-full w-full bg-[#00000055]">
                      <button
                        onClick={() => setType(2)}
                        className={`bg-[#FFFFFF] border-[1px] border-[#CD8E42] h-[38px] rounded-[10px] items-center px-[15px] text-[14px] text-[#071524] font-inter font-normal`}
                      >
                        View All Card PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" container max-w-full md:max-w-[50%] md:my-4 xl:my-8 flex xl:hidden items-center justify-center self-center">
                <div className="grid  grid-cols-2  gap-3">
                  <div
                    className="relative w-full h-auto rounded-[10px] overflow-hidden"
                    onClick={() => setType(1)}
                  >
                    <img
                      src={state.item?.cover_image_url}
                      className="w-full h-full object-fill"
                    />

                    <div className="absolute z-10 inset-0 flex items-center justify-center h-full w-full bg-[#00000055]">
                      <IoPlayCircleSharp
                        size={40}
                        color="#FF9500"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>

                  <div
                    className="w-full h-auto relative rounded-[10px] overflow-hidden"
                    onClick={() => setType(2)}
                  >
                    <img
                      src={state.item?.cover_image_url}
                      className="w-full h-full object-fill"
                    />

                    <div className="absolute z-10 inset-0 flex items-center justify-center h-full w-full bg-[#00000055]">
                      <button
                        className={`bg-[#FFFFFF] border-[1px] border-[#CD8E42] h-[38px] rounded-[10px] items-center px-[15px] text-[14px] text-[#071524] font-inter font-normal`}
                      >
                        View All Card PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {(type == 1 || type == 2) && (
        <Modal
          width={
            (type == 1 || type == 2) && window.innerWidth < 400
              ? "auto"
              : type == 2
              ? theme_preview?.preview_images?.length < 3
                ? "auto"
                : "60%"
              : "350px"
          }
          styles={{
            content: {
              backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
              borderRadius: 20,
            },
            header: { backgroundColor: "transparent" },
          }}
          title={""}
          open={type == 1 ? true : false || type == 2 ? true : false}
          onCancel={() => setType(0)}
          footer={""}
          centered
          closeIcon={<></>}
        >
          <div className="flex items-center justify-between ">
            <div className="font-baskerville text-clamp-popup-title text-[#fff]">
              {type == 1 ? "Video Preview" : "Card PDF Preview"}
            </div>
            <IoMdClose size={30} color="#fff" onClick={() => setType(0)} />
          </div>
          {type == 1 && (
            <div className="inset-0 relative self-center w-auto h-auto rounded-[10px] overflow-hidden mt-4 items-center justify-center flex">
              <video
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
                className="w-full h-full object-contain"
              >
                <source
                  src={theme_preview?.preview_video?.url}
                  type="video/mp4"
                />
              </video>
            </div>
          )}
          {type == 2 && (
            <div className=" w-full h-[32rem] rounded-[10px] px-4 py-4 flex bg-[#fff] mt-4">
              <div className="overflow-hidden w-full flex  py-2 gap-3 overflow-x-auto scrollBar-color">
                {theme_preview?.preview_images.map((v, i) => (
                  <div
                    key={i}
                    style={{ backgroundImage: `url(${v.image_url})` }}
                    className="min-w-[17rem] w-[17rem] h-full rounded-[10px] object-fill bg-cover overflow-hidden items-end flex"
                  >
                    <div className="bg-[#06111D9D] py-3 flex w-full items-center font-inter font-medium text-center text-[#fff] text-[16px] justify-center">
                      {v.image_name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default TemplateDetailPage;
