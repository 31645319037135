import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, MediumText } from "../../../utils/layoutUtils";
import card from "../../../assets/svgimg/card.png";
import adfooter from "../../../assets/adfooter.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { get_orders, get_single_order, get_video } from "../../../utils/apis";
import { Modal } from "antd";
import { useInterval } from "../../../utils/useInterval";
import { isTokenExpired } from "../../../utils/utils";
import { openNotificationWithIcon } from "../../../App";
import myContext from "../../../state/myContext";

const DownloadTemplateEdit = () => {
  const user = JSON.parse(localStorage.getItem("token"));
  const videoRef = useRef(null);
  const { setShow_login_modal } = useContext(myContext);
  const { post_order, video_data, selected_json, theme_preview } = useSelector(
    (state) => state.user
  );
  const [status, setStatus] = useState({});
  const navigate = useNavigate();
  const [isPlaying, setPlaying] = useState(true);
  const getvideo_url = (res) => {
    if (user == null) {
      setShow_login_modal(true);
      setPlaying(false);
      return;
    }
    get_video(res?.data?.body?.items[0]?.request_id, setShow_login_modal);
  };

  const fetchData = async () => {
    if (isTokenExpired()) {
      openNotificationWithIcon(
        "error",
        "Alert",
        "Your token has expired, Please login"
      );
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      setShow_login_modal(true);
      setPlaying(false);
      return;
    }
    if (user == null) {
      setShow_login_modal(true);
      setPlaying(false);
      return;
    }
    try {
      const res = await get_single_order(post_order.order_id);
      if (res?.data?.statusCode == "200") {
        if (res?.data?.body?.items[0]?.restatus == "SUCCESS") {
          setPlaying(false);
          getvideo_url(res);
        }
        setStatus(res?.data?.body);
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useInterval(
    () => {
      //counter function
      fetchData();
    },
    // Passing in the delay parameter. null stops the counter.
    isPlaying ? 60000 : null
  );
  useEffect(() => {
    if (!isPlaying && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [isPlaying]);

  const downloadVideo = () => {
    const link = document.createElement("a");
    link.href = video_data?.video_url;
    link.download = `${selected_json?.themeName}.mp4`; // You can set the file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="flex gap-4 my-6 mx-4 flex-col items-center">
        <div className="flex items-center gap-4">
          <div class="checkbox-wrapper-65">
            <label htmlFor="cbk1-65-a">
              <input type="checkbox" id="cbk1-65-a" />
              <span class="cbx">
                <svg width="12px" height="11px" viewBox="0 0 12 11">
                  <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                </svg>
              </span>
              <span className="font-inter text-[16px] font-light leading-[20px] text-[##071524]">
                Video
              </span>
            </label>
          </div>
          <div class="checkbox-wrapper-65">
            <label htmlFor="cbk1-65">
              <input type="checkbox" id="cbk1-65" />
              <span class="cbx">
                <svg width="12px" height="11px" viewBox="0 0 12 11">
                  <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                </svg>
              </span>
              <span className="font-inter text-[16px] font-light leading-[20px] text-[##071524]">
                Card’s PDF
              </span>
            </label>
          </div>
        </div>
        <div className="relative w-[17rem]   overflow-hidden gap-7 flex flex-col">
          <img
            src={theme_preview?.preview_images[0]?.image_url}
            className="w-full h-[30rem] object-fill rounded-[10px]"
          />
          <Button
            title={
              Object.keys(status)?.length > 0 &&
              status?.items[0]?.restatus == "SUCCESS"
                ? "Downlod"
                : "In Processing"
            }
            onClick={() => downloadVideo()}
            className={"w-full h-[50px] text-[18px]"}
          />
        </div>
      </div>
      <Modal
        width={window.innerWidth < 500 ? "100%" : "70%"}
        styles={{
          content: {
            backgroundColor: "#fff",
            paddingInline: 0,
            paddingTop: 0,
            paddingBottom: 0,
            overflow: "hidden",
          },
          header: { backgroundColor: "transparent" },
          footer: {},
        }}
        title={""}
        open={isPlaying}
        footer={() => (
          <img src={adfooter} className="w-full h-auto object-contain" />
        )}
        centered
        closeIcon={<></>}
      >
        <div className="text-[30px] h-auto  text-[#fff] text-center font-inter">
          <p className="text-clamp-heading text-[#FF9500] text-center font-inter font-semibold pt-clamp-y-sm">
            Go Green Go Digital
          </p>
          <p className="text-clamp-base18 px-clamp-x-md text-[#071524] text-center font-inter font-medium pt-clamp-y-sm">
            Please wait your Invitation card (PDF+video) Generating, Meanwhile
            <span className="font-semibold"> do not</span> refresh & close the
            window
          </p>
          <div className="h-full justify-center items-center flex mt-3">
            {isPlaying && (
              <video
                ref={videoRef}
                loop
                autoPlay={true}
                className="w-[60%] h-full object-contain rounded-md"
              >
                <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4" />
              </video>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadTemplateEdit;
