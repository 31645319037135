import React, { useContext, useRef, useState } from "react";
import PageFrame from "../components/PageFrame";
import icon_logout from "../assets/icons/icon_logout.svg";
import myContext from "../state/myContext";
import { FiLogIn } from "react-icons/fi";
import user_circle from "../assets/user_circle.png";
import plan1 from "../assets/plan1.png";
import plan2 from "../assets/plan2.png";
import plan3 from "../assets/plan3.png";
import planBg from "../assets/planBg.png";
// import AudioPlayer from "../components/AudioPlayer";

const Profile = () => {
  const { setShow_login_modal } = useContext(myContext);

  return (
    <>
      {/* <AudioPlayer /> */}
      <PageFrame current_page={"Profile"}>
        <div className="flex flex-wrap items-center justify-center gap-4 p-4">
          <div className="border border-[#FEECA799] rounded-lg p-6 gap-1 flex flex-col items-center w-60 bg-[#FEECA799] relative">
            <h2 className="text-2xl sm:text-3xl font-extrabold font-inter text-[#103935]">
              FREE
            </h2>
            <p className="text-base sm:text-lg text-[#103935] font-inter">
              Basic Plan
            </p>
            <div
              style={{ backgroundImage: `url(${planBg})` }}
              className="w-full h-[100px] bg-no-repeat bg-cover bg-center items-center justify-center flex"
            >
              <img
                src={plan1}
                alt="Basic Plan"
                className="w-[60%] h-[60%] object-contain"
              />
            </div>

            <h3 className="text-5xl sm:text-7xl font-extrabold font-inter text-[#103935]">
              1
            </h3>
            <p className="text-lg sm:text-2xl text-[#213B57] text-center font-inter font-semibold">
              FREE Theme can edit
            </p>
            <button className="mt-4 px-5 py-1 bg-white text-[#071524] text-lg rounded-lg border-[#103935] border">
              Get Started
            </button>
            <div className="top-0 left-0 px-3 py-1 font-inter font-medium bg-[#FF9500] text-[#ffffff] text-sm rounded-lg absolute">
              Active plan
            </div>
          </div>
          <div className="flex flex-col justify-center items-center ">
            <img src={user_circle} className="h-[15%] w-[15%] object-contain" />
            {/* <div className="h-[9rem] w-[9rem] bg-gradient-to-r from-[#FFF5D7] to-[#FDEBCB] border-[#0715241A] border rounded-full justify-center items-center flex">
          </div> */}
            {/* <p className="text-[#000000] text-clamp-sm font-inter mt-2">
            +91 9098583213
          </p> */}
            {localStorage.getItem("token") == null ? (
              <button
                onClick={() => setShow_login_modal(true)}
                className={`flex gap-2 mt-4 bg-transparent h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-clamp-x-slider-button text-clamp-base18 text-[#071524] font-inter font-medium border border-[#FF0000]`}
              >
                <FiLogIn size={20} color="#103935" />
                Log In
              </button>
            ) : (
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  // window.location.reload();
                }}
                className={`flex gap-2 mt-4 bg-transparent h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-clamp-x-slider-button text-clamp-base18 text-[#071524] font-inter font-medium border border-[#FF0000]`}
              >
                <img
                  src={icon_logout}
                  className="h-[20px] w-[20px] object-contain"
                />
                Log Out
              </button>
            )}
          </div>
        </div>
      </PageFrame>
    </>
  );
};

export default Profile;
