import React, { useEffect, useRef, useState } from "react";
import {
  FooterLinkText,
  HeadingText,
  MediumText,
  TitleText,
} from "../utils/layoutUtils";
import { ReactComponent as Grass } from "../assets/svgimg/grass.svg";
import twitterIcon from "../assets/icons/icon_twitter.svg";
import linkedinIcon from "../assets/icons/icon_linkedin.svg";
import googleIcon from "../assets/icons/icon_google.svg";
import icon_phone from "../assets/icons/icon_phone.svg";
import facebookIcon from "../assets/icons/icon_facebook.svg";
import mailIcon from "../assets/icons/icon_envalop.svg";
import { useResponsiveWidth } from "../utils/utils";

const FooterComponent = () => {
  const dynamicWidth = useResponsiveWidth();
  return (
    <div className="w-full flex  flex-col ">
      <div className="w-full flex flex-col items-center p-clamp-md mt-2 md:-mb-10 lg:-mb-20 z-10 ">
        <HeadingText>Contact Us</HeadingText>
        <TitleText className={"text-clamp-base18 mt-2 md:mt-4"}>
          We'd love to hear from you! Whether you have questions, feedback, or
          need assistance, our team is here to help.
        </TitleText>
        <div className="flex flex-col md:flex-row gap-6 xl:gap-8 items-center justify-center w-[95%] xl:w-[70%] mt-5 ">
          <div className="flex items-center gap-4">
            <div
              className={`bg-gradient-to-r from-[#FFF090] to-[#FABF55] shadow-2xl shadow-[#91B4E426]  border-[1px] border-[#CD8E42] h-clamp-sm w-clamp-sm rounded-full flex items-center  text-[14px] text-[#071524] font-inter font-normal justify-center `}
            >
              <img src={mailIcon} className="h-[70%] w-[70%] object-contain" />
            </div>
            <TitleText className={"flex-1"}>info@invitecard.me</TitleText>
          </div>
          <div className="flex items-center gap-4">
            <div
              className={`bg-gradient-to-r from-[#FFF090] to-[#FABF55] shadow-2xl shadow-[#91B4E426]  border-[1px] border-[#CD8E42] h-clamp-sm w-clamp-sm rounded-full flex items-center  text-[14px] text-[#071524] font-inter font-normal justify-center `}
            >
              <img
                src={icon_phone}
                className="h-[70%] w-[70%] object-contain"
              />
            </div>
            <TitleText className={"flex-1"}>+91 9479793733</TitleText>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#072522] flex items-center justify-center flex-col ">
        <iframe
          src="/assets/Footer.html"
          title="Animated Content"
          height={dynamicWidth}
          width={"100%"}
          style={{ border: "none", objectFit: "fill" }}
          className="relative"
        />
        <div className="w-full bg-[#072522] flex items-center justify-center flex-col -mt-1 lg:-mt-4 z-20">
          <div className="w-[90%] xl:w-[80%] flex flex-col sm:flex-row gap-4 md:gap-8 justify-between   ">
            <div className="w-full flex flex-col xl:flex-row gap-4 md:gap-8 justify-between">
              <div className="gap-2 md:gap-6 flex flex-col flex-1">
                <div className="flex flex-col gap-3">
                  <MediumText>Useful Links</MediumText>
                  <div className="w-[20px] h-[2px] bg-[#02BFA1]" />
                </div>

                <FooterLinkText to={"/faq"}>FAQ</FooterLinkText>
                <FooterLinkText>Blog</FooterLinkText>
                <FooterLinkText to={"/refund-policy"}>
                  Refund Policy
                </FooterLinkText>
                <FooterLinkText to={"/privacy-policy"}>
                  Privacy Policy
                </FooterLinkText>
                <FooterLinkText to={"/terms-condition"}>
                  Terms & Conditions
                </FooterLinkText>
              </div>
              <div className="gap-2 md:gap-6 flex flex-col flex-1">
                <div className="flex flex-col gap-3">
                  <MediumText>Important Links</MediumText>
                  <div className="w-[20px] h-[2px] bg-[#02BFA1]" />
                </div>

                <FooterLinkText>Wedding Video Invitation</FooterLinkText>
                {/* <FooterLinkText>Engagement Video Invitation</FooterLinkText>
                <FooterLinkText>
                  Valentine's Week Video Invitation
                </FooterLinkText>
                <FooterLinkText>Birthday Video Invitation</FooterLinkText> */}
              </div>
            </div>
            <div className="w-full flex flex-col xl:flex-row gap-4 md:gap-8 justify-between mt-0">
              <div className="gap-2 md:gap-6 flex flex-col flex-1">
                <div className="hidden flex-col gap-3 opacity-0 md:flex">
                  {/* <MediumText>'</MediumText> */}
                  <div className="w-[20px] h-[2px] bg-[#02BFA1]" />
                </div>

                {/* <FooterLinkText>Anniversary Video Invitation</FooterLinkText>
                <FooterLinkText>House Warming Video Invitation</FooterLinkText>
                <FooterLinkText>House Warming Video Invitation</FooterLinkText>
                <FooterLinkText>Tread Ceremony Video Invitation</FooterLinkText> */}
              </div>
              <div className="gap-2 md:gap-6 flex flex-col flex-1">
                {/* <div className="flex flex-col gap-3">
                <MediumText>Available On</MediumText>
                <div className="w-[20px] h-[2px] bg-[#02BFA1]" />
              </div>
              <img src={googlePlay} className="w-[190px] cursor-pointer" /> */}
                {/* <MediumText className={"text-[14px]"}>Get in Touch</MediumText> */}
                <div className="flex flex-col gap-3">
                  <MediumText>Get in Touch</MediumText>
                  <div className="w-[20px] h-[2px] bg-[#02BFA1]" />
                </div>
                <div className="flex gap-3">
                  <img
                    src={twitterIcon}
                    className="w-[30px] h-[30px] cursor-pointer"
                  />
                  <img
                    src={linkedinIcon}
                    className="w-[30px] h-[30px] cursor-pointer"
                  />
                  <img
                    src={googleIcon}
                    className="w-[30px] h-[30px] cursor-pointer"
                  />
                  <img
                    src={facebookIcon}
                    className="w-[30px] h-[30px] cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-[90%] gap-2 md:w-[80%] mt-4 md:mt-8 items-center justify-between py-4 border-t-[1px] border-t-[#172635]">
            <MediumText className={"text-[14px] text-center"}>
              Copyright © invitecard.me 2024.All rights reserved. | Product of{" "}
              <span className="text-[#FF9500]">LEGACY VAN</span>
            </MediumText>
            <MediumText className={"text-[14px]"}>
              {/* Made with in ❤️ India */}
              Made in india with ❤️
            </MediumText>
          </div>
          <Grass className="h-auto flex w-full" />
        </div>
      </div>
    </div>
  );
};
// 072522
export default FooterComponent;
