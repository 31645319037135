import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../utils/layoutUtils";
import { useNavigate } from "react-router-dom";
import { TfiArrow } from "react-icons/tfi";
import CoverEdit from "./CoverEdit";
import { useSelector } from "react-redux";
import { store } from "../../../redux/store";
import {
  setDeleted_video,
  setEdit_Page,
  setEvent_Page,
  setJson_Data,
  setSelected_Pages,
  setSnap_shot,
} from "../../../redux/slice/userSlice";
import EventDeletePopup from "../../../components/EventDeletePopup";
import SnapShot from "../../../components/SnapShot";
import { fields } from "../../../utils/data";
import { Modal } from "antd";
import { IoMdClose } from "react-icons/io";
import { ReactComponent as Confirm } from "../../../assets/confirm2.svg";

const EventTemplateEdit = ({}) => {
  const [modalWidth, setModalWidth] = useState("100%"); // Default width
  const [is_confirmed, setIs_confirmed] = useState(true);
  const [open, setOpen] = useState(false);
  const canvasRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [eventList, setEventList] = useState([]);
  const {
    selected_pages,
    selected_json,
    edit_page,
    event_page,
    deleted_video,
    snap_shot,
  } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    setEventList(selected_pages?.slice(2));
  }, []);
  const [sections, setSections] = useState([]);
  const [imageBoxes, setImageBoxes] = useState([]);
  const [complete_json, setComplete_json] = useState({});
  const handleSnapshot = () => {
    const updatedSnapShot = [...snap_shot];

    if (deleted_video[edit_page + event_page - 1]?.deleted) {
      updatedSnapShot[edit_page + event_page - 1] = { snap: "" };
      store.dispatch(setSnap_shot(updatedSnapShot));
      // store.dispatch(
      //   setSnap_shot(updatedSnapShot.splice(edit_page + event_page - 1, 1))
      // );
      return;
    }
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");

    updatedSnapShot[edit_page + event_page - 1] = { snap: dataURL };

    store.dispatch(setSnap_shot(updatedSnapShot)); // Dispatch the updated array
  };

  const onNext = () => {
    // handleSnapshot();
    // if (
    //   !deleted_video[edit_page + event_page - 1]?.deleted &&
    //   sections?.filter((v) => v.text == "").length > 0
    // ) {
    //   alert("Please fill all fields");
    //   return;
    // }
    const filter_section = sections.map((section) => {
      // if (section.text == "") {
      //   // Remove placeholder after updating text
      //   // delete section.placeholder;
      //   section.text = section.placeholder;
      // }
      if (fields.includes(section.placeholder) && section.text == "") {
        section.text = "";
      }
      if (!fields.includes(section.placeholder) && section.text == "") {
        section.text = section.placeholder;
      }
      return section;
    });
    const complete_section = filter_section.concat(imageBoxes);

    // Create a new state object with updated sections for the specific page
    const new_pages = complete_json.pages.map((page, index) => {
      if (index === edit_page + event_page - 1) {
        return {
          ...page,
          sections: complete_section,
        };
      }
      return page;
    });

    const new_complete_json = {
      ...complete_json,
      pages: new_pages,
    };

    // Dispatch the new JSON to the store
    store.dispatch(setSelected_Pages(new_complete_json.pages));
    store.dispatch(setEdit_Page(edit_page + 1));
    store.dispatch(setJson_Data(new_complete_json));
    navigate("/template/" + selected_json?.themeName + "/edit/add-music");
  };

  const onNextTab = () => {
    console.log("call here");

    // handleSnapshot();
    // if (
    //   !deleted_video[edit_page + event_page - 1]?.deleted &&
    //   sections?.filter((v) => v.text == "").length > 0
    // ) {
    //   alert("Please fill all fields");
    //   return;
    // }
    const filter_section = sections.map((section) => {
      // if (section.text == "") {
      //   // Remove placeholder after updating text
      //   // delete section.placeholder;
      //   section.text = section.placeholder;
      // }
      if (fields.includes(section.placeholder) && section.text == "") {
        section.text = "";
      }
      if (!fields.includes(section.placeholder) && section.text == "") {
        section.text = section.placeholder;
      }
      return section;
    });
    const complete_section = filter_section.concat(imageBoxes);
    const new_pages = complete_json.pages.map((page, index) => {
      if (index === edit_page + event_page - 1) {
        return {
          ...page,
          sections: complete_section,
        };
      }
      return page;
    });
    const new_complete_json = {
      ...complete_json,
      pages: new_pages,
    };
    store.dispatch(setSelected_Pages(new_complete_json.pages));
    store.dispatch(setJson_Data(new_complete_json));
    store.dispatch(setEvent_Page(event_page + 1));
  };

  useEffect(() => {
    setComplete_json(selected_json);
  }, [selected_json]); // Adding dependency to ensure it runs when selected_json changes
  useEffect(() => {
    const handleResize = () => {
      setModalWidth(window.innerWidth < 500 ? "100%" : "50%");
    };

    // Initial width check
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const restore_page = () => {
    const new_page = deleted_video.map((page, index) => {
      if (index === edit_page + event_page - 1) {
        return {
          ...page,
          deleted: !deleted_video[edit_page + event_page - 1]?.deleted,
        };
      }
      return page;
    });
    store.dispatch(setDeleted_video(new_page));
  };
  return (
    <>
      <div className="flex gap-3 my-6 flex-wrap justify-center">
        {eventList?.map((item, index) => (
          <div
            key={index}
            className={`${
              event_page >= index + 1
                ? "bg-gradient-to-r from-[#FFF090] to-[#FABF55]"
                : "bg-[#fff]"
            } relative border-[1px] border-[#CD8E42] h-[32px] rounded-full items-center px-[14px] text-[12px] text-[#07152499] font-inter font-normal flex`}
          >
            {/* <div className="h-[20px] w-[20px] rounded-full flex items-center justify-center bg-[#103935] absolute -top-[8px] -right-[8px]">
              <TfiArrow size={13} color="#fff" />
            </div> */}
            {item?.pageName}
          </div>
        ))}
      </div>
      <div className="flex gap-4 mb-6 mx-4 flex-col md:flex-row">
        <div className="flex-1  items-end hidden md:flex">
          {deleted_video[edit_page + event_page - 1]?.deleted || (
            <Button
              title={`Remove ${eventList[event_page - 1]?.pageName}`}
              bg={"bg-[#fff]"}
              className={"text-[#FF0000] border-[#FF0000] px-10"}
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
        <div className="flex justify-center">
          {!deleted_video[edit_page + event_page - 1]?.deleted ? (
            <div className="flex flex-col">
              <SnapShot
                jsonData={
                  selected_pages[Number(edit_page) + Number(event_page - 1)]
                }
                sections={sections}
                imageBoxes={imageBoxes}
                canvasRef={canvasRef}
              />
              <CoverEdit
                jsonData={
                  selected_pages[Number(edit_page) + Number(event_page - 1)]
                }
                sections={sections}
                setSections={setSections}
                imageBoxes={imageBoxes}
                setImageBoxes={setImageBoxes}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-3 my-20">
              <h1 className="text-center font-baskerville font-semibold text-clamp-heading text-[#071524]">
                This page has been deleted
              </h1>
              <Button title={"Restore the page"} onClick={restore_page} />
            </div>
          )}
        </div>
        {deleted_video[edit_page + event_page - 1]?.deleted || (
          <div className="flex-1  items-end flex md:hidden">
            <Button
              title={`Remove ${eventList[event_page - 1]?.pageName}`}
              bg={"bg-[#fff]"}
              className={"text-[#FF0000] border-[#FF0000] px-10"}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
        <div className="flex flex-1  items-end justify-between md:justify-end gap-3">
          <Button
            title={"Back"}
            bg={"bg-[#fff]"}
            className={"px-8"}
            onClick={() => {
              event_page == 1 && store.dispatch(setEdit_Page(edit_page - 1));
              event_page == 1
                ? navigate(-1)
                : store.dispatch(setEvent_Page(event_page - 1));
            }}
          />
          <Button
            title={"Next"}
            className={"px-8"}
            onClick={() => {
              eventList?.length == event_page ? setOpen(true) : onNextTab();
            }}
          />
        </div>
      </div>
      <EventDeletePopup isOpen={isOpen} setIsOpen={setIsOpen} />
      <Modal
        width={modalWidth}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="flex items-center justify-between p-4">
          <div className="font-baskerville text-clamp-popup-title text-[#fff] text-lg md:text-xl">
            Confirm Your Details
          </div>
          <IoMdClose
            size={30}
            color="#fff"
            onClick={() => setOpen(false)}
            className="cursor-pointer"
          />
        </div>

        <div className="confirm-shadow flex flex-col items-center">
          <Confirm className="h-auto flex w-full max-w-[200px] self-center mx-auto" />
        </div>

        <p className="font-inter text-sm lg:text-lg md:text-xl text-[#fff] text-center mx-4">
          Please ensure all details are correct before proceeding. Once
          confirmed, you won’t be able to make any further edits.
        </p>

        <div className="mt-4 flex flex-col justify-center items-center gap-4">
          <div className="checkbox-wrapper-65">
            <label htmlFor="cbk1-65" className="">
              <span className="font-inter ml-2 text-[16px] font-light leading-[20px] text-[#fff]">
                <input
                  type="checkbox"
                  id="cbk1-65"
                  checked={is_confirmed}
                  onChange={() => setIs_confirmed(!is_confirmed)}
                />
                <span className="cbx">
                  <svg width="12px" height="11px" viewBox="0 0 12 11">
                    <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                  </svg>
                </span>
                {"  "}
                Confirm everything is perfect
              </span>
            </label>
          </div>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-3 w-full">
            <Button
              title={"Edit"}
              bg={"bg-[#fff]"}
              className={"px-8"}
              onClick={() => {
                store.dispatch(setEdit_Page(0));
                store.dispatch(setEvent_Page(1));
                navigate(
                  "/template/" + selected_json?.themeName + "/edit/cover-page"
                );
              }}
            />
            <Button
              title={"Confirm"}
              className={"px-8"}
              onClick={() => {
                onNext();
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EventTemplateEdit;
