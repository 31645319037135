// src/components/NotFound.js

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.message}>Page Not Found</p>
      <Link to="/" style={styles.link}>
        Go to Home
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f2f2f2",
  },
  heading: {
    fontSize: "10rem",
    margin: "0",
  },
  message: {
    fontSize: "2rem",
    margin: "0.5rem 0",
  },
  link: {
    fontSize: "1.5rem",
    color: "#007bff",
    textDecoration: "none",
  },
};

export default NotFound;
