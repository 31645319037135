import React, { useEffect, useState } from "react";

const ModalComponent = ({ isOpen, setIsOpen, onClose, children }) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 overflow-y-auto z-50 flex items-end md:items-center justify-center ">
          <div className="fixed inset-0 transition-opacity">
            <div
              className="fixed inset-0 bg-black opacity-30"
              onClick={handleClose}
            ></div>
          </div>

          <div className="relative bg-white md:rounded-[40px] overflow-hidden transform transition-transform max-w-full md:max-w-[70%] w-full">
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;
