import { Input } from "antd";
import React from "react";

const CustomInput = ({
  placeholder,
  value,
  onChange,
  isPassword = false,
  type,
  name,
  label,
  className,
  readOnly,
  max,
  maxLength,
}) => {
  return (
    <div className={`flex flex-col mt-4 ${className}`}>
      {label && (
        <label className="text-[#fff] font-inter text-clamp-sm">{label}</label>
      )}
      {isPassword ? (
        <Input.Password
          className="bg-[#fff]  py-[10px] md:py-[15px] px-3 mt-1 rounded-[5px] md:rounded-[10px] flex-1 font-inter text-clamp-sm text-[#071524] placeholder:text-[#071524] focus:outline-none hover:outline-none hover:border-none focus:border-none border-none"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type}
          name={name}
        />
      ) : (
        <Input
          className="bg-[#fff]   py-[10px] md:py-[15px] px-3 mt-1 rounded-[5px] md:rounded-[10px] flex-1 font-inter text-clamp-sm text-[#071524] placeholder:text-[#333333] focus:outline-none hover:outline-none hover:border-none focus:border-none border-none"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type}
          name={name}
          readOnly={readOnly}
          max={max}
          maxLength={maxLength}
        />
      )}
    </div>
  );
};

export default CustomInput;
