import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../utils/layoutUtils";
import { useNavigate } from "react-router-dom";
import CoverEdit from "./CoverEdit";
import EventDeletePopup from "../../../components/EventDeletePopup";
import { useSelector } from "react-redux";
import { store } from "../../../redux/store";
import {
  setDeleted_video,
  setEdit_Page,
  setJson_Data,
  setSelected_Pages,
  setSnap_shot,
} from "../../../redux/slice/userSlice";
import SnapShot from "../../../components/SnapShot";
import { fields } from "../../../utils/data";

const FamilyDetailTemplateEdit = () => {
  const canvasRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [imageBoxes, setImageBoxes] = useState([]);
  const { selected_pages, selected_json, snap_shot, edit_page, deleted_video } =
    useSelector((state) => state.user);
  const [complete_json, setComplete_json] = useState({});

  const handleSnapshot = () => {
    const updatedSnapShot = [...snap_shot];

    if (deleted_video[edit_page]?.deleted) {
      updatedSnapShot[edit_page] = { snap: "" };
      store.dispatch(setSnap_shot(updatedSnapShot));
      // store.dispatch(setSnap_shot(updatedSnapShot.splice(edit_page, 1)));
      return;
    }
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");

    updatedSnapShot[edit_page] = { snap: dataURL };

    store.dispatch(setSnap_shot(updatedSnapShot)); // Dispatch the updated array
  };

  const onNext = () => {
    // handleSnapshot();
    const filter_section = sections.map((section) => {
      // if (section.text == "") {
      //   // Remove placeholder after updating text
      //   // delete section.placeholder;
      //   section.text = section.placeholder;
      // }
      if (fields.includes(section.placeholder) && section.text == "") {
        section.text = "";
      }
      if (!fields.includes(section.placeholder) && section.text == "") {
        section.text = section.placeholder;
      }
      return section;
    });
    const complete_section = filter_section.concat(imageBoxes);

    // Create a new state object with updated sections for the specific page
    const new_pages = complete_json.pages.map((page, index) => {
      if (index === edit_page) {
        return {
          ...page,
          sections: complete_section,
        };
      }
      return page;
    });

    const new_complete_json = {
      ...complete_json,
      pages: new_pages,
    };

    // Dispatch the new JSON to the store
    store.dispatch(setSelected_Pages(new_complete_json.pages));
    store.dispatch(setEdit_Page(edit_page + 1));
    store.dispatch(setJson_Data(new_complete_json));
    navigate("/template/" + selected_json?.themeName + "/edit/events");
  };

  useEffect(() => {
    setComplete_json(selected_json);
  }, [selected_json]); // Adding dependency to ensure it runs when selected_json changes
  const restore_page = () => {
    const new_page = deleted_video.map((page, index) => {
      if (index === edit_page) {
        return {
          ...page,
          deleted: !deleted_video[edit_page]?.deleted,
        };
      }
      return page;
    });
    store.dispatch(setDeleted_video(new_page));
  };
  return (
    <>
      <div className="flex gap-4 my-6 mx-0 flex-col md:flex-row ">
        <div className="flex-1 items-end hidden md:flex ml-4">
          {deleted_video[edit_page]?.deleted || (
            <Button
              title={"Remove family details"}
              bg={"bg-[#fff]"}
              className={"text-[#FF0000] border-[#FF0000] px-10"}
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
        <div className="flex justify-center">
          {!deleted_video[edit_page]?.deleted ? (
            <div className="flex flex-col">
              <SnapShot
                jsonData={selected_pages[edit_page]}
                sections={sections}
                imageBoxes={imageBoxes}
                canvasRef={canvasRef}
              />
              <CoverEdit
                jsonData={selected_pages[edit_page]}
                sections={sections}
                setSections={setSections}
                imageBoxes={imageBoxes}
                setImageBoxes={setImageBoxes}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-3 my-20">
              <h1 className="text-center font-baskerville font-semibold text-clamp-heading text-[#071524]">
                This page has been deleted
              </h1>
              <Button title={"Restore the page"} onClick={restore_page} />
            </div>
          )}
        </div>

        {deleted_video[edit_page]?.deleted || (
          <div className="flex-1  items-end flex md:hidden ml-4">
            <Button
              title={"Remove family details"}
              bg={"bg-[#fff]"}
              className={"text-[#FF0000] border-[#FF0000] px-10"}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
        <div className="flex flex-1  items-end justify-between md:justify-end gap-3 md:ml-0 ml-4 mr-4">
          <Button
            title={"Back"}
            bg={"bg-[#fff]"}
            className={"px-8"}
            onClick={() => {
              store.dispatch(setEdit_Page(edit_page - 1));
              navigate(-1);
            }}
          />
          <Button
            title={"Next"}
            className={"px-8"}
            onClick={() => {
              // if (
              //   !deleted_video[edit_page]?.deleted &&
              //   sections?.filter((v) => v.text == "").length > 0
              // ) {
              //   alert("Please fill all fields");
              //   return;
              // }
              onNext();
            }}
          />
        </div>
      </div>
      <EventDeletePopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default FamilyDetailTemplateEdit;
