import React from "react";
import PageFrame from "../components/PageFrame";
import plan1 from "../assets/plan1.png";
import plan2 from "../assets/plan2.png";
import plan3 from "../assets/plan3.png";
import planBg from "../assets/planBg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PricingCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selected_json } = useSelector((state) => state.user);

  const handleClick = () => {
    navigate("/template/" + selected_json?.themeName + "/edit/cover-page", {
      state: location.state,
    });
  };

  return (
    <PageFrame current_page={"Plans"}>
      <div className="flex flex-wrap justify-center items-center gap-2 p-6">
        {/* Basic Plan */}
        <div className="border border-[#FEECA799] rounded-lg gap-1 p-6 flex flex-col items-center w-60 bg-[#FEECA799]">
          <h2 className="text-2xl sm:text-3xl font-extrabold font-inter text-[#103935]">
            FREE
          </h2>
          <p className="text-base sm:text-lg text-[#103935] font-inter">
            Basic Plan
          </p>
          <div
            style={{ backgroundImage: `url(${planBg})` }}
            className="w-full h-[100px] bg-no-repeat bg-cover bg-center items-center justify-center flex"
          >
            <img
              src={plan1}
              alt="Basic Plan"
              className="w-[60%] h-[60%] object-contain"
            />
          </div>

          <h3 className="text-5xl sm:text-7xl font-extrabold font-inter text-[#103935]">
            1
          </h3>
          <p className="text-lg sm:text-2xl text-[#213B57] text-center font-inter font-semibold">
            FREE Theme can edit
          </p>
          <button
            onClick={() => handleClick()}
            className="mt-4 px-5 py-1 bg-white text-[#071524] text-lg rounded-lg border-[#103935] border"
          >
            Get Started
          </button>
        </div>

        {/* Premium Plan */}
        <div className="border border-[#DCC7AE] rounded-lg gap-1 p-6 flex flex-col items-center w-60 bg-[#FEE482]">
          <h2 className="text-2xl sm:text-3xl font-extrabold font-inter text-[#103935]">
            ₹149
          </h2>
          <p className="text-base sm:text-lg text-[#103935] font-inter">
            Premium
          </p>
          <div
            style={{ backgroundImage: `url(${planBg})` }}
            className="w-full h-[100px] bg-no-repeat bg-cover bg-center items-center justify-center flex"
          >
            <img
              src={plan2}
              alt="Premium Plan"
              className="w-[60%] h-[60%] object-contain"
            />
          </div>
          <h3 className="text-5xl sm:text-7xl font-extrabold font-inter text-[#103935]">
            10
          </h3>
          <p className="text-lg sm:text-2xl text-[#213B57] text-center font-inter font-semibold">
            PREMIUM Theme can edit
          </p>
          <button
            onClick={() => handleClick()}
            className="mt-4 px-5 py-1 bg-[#103935] text-[#FFFFFF] text-lg rounded-lg border-[#103935] border"
          >
            Buy Now
          </button>
        </div>

        {/* Pro Plan */}
        <div className="border border-[#CD8E42] rounded-lg gap-1 p-6 flex flex-col items-center w-60 bg-[#FFC573]">
          <h2 className="text-2xl sm:text-3xl font-extrabold font-inter text-[#103935]">
            ₹249
          </h2>
          <p className="text-base sm:text-lg text-[#103935] font-inter">Pro</p>
          <div
            style={{ backgroundImage: `url(${planBg})` }}
            className="w-full h-[100px] bg-no-repeat bg-cover bg-center items-center justify-center flex"
          >
            <img
              src={plan3}
              alt="Pro Plan"
              className="w-[60%] h-[60%] object-contain"
            />
          </div>
          <h3 className="text-5xl sm:text-7xl font-extrabold font-inter text-[#103935]">
            20
          </h3>
          <p className="text-lg sm:text-2xl text-[#213B57] text-center font-inter font-semibold">
            PREMIUM Theme can edit
          </p>
          <button
            className="mt-4 px-5 py-1 bg-[#103935] text-[#FFFFFF] text-lg rounded-lg border-[#103935] border"
            onClick={() => handleClick()}
          >
            Buy Now
          </button>
        </div>
      </div>
    </PageFrame>
  );
};

export default PricingCard;
