import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, PageLoader, RadioBtn } from "../../../utils/layoutUtils";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { IoMdClose } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaPlay } from "react-icons/fa6";
import { ReactComponent as Confirm } from "../../../assets/confirm.svg";
import { useSelector } from "react-redux";
import { setEdit_Page, setEvent_Page } from "../../../redux/slice/userSlice";
import { store } from "../../../redux/store";
import axios from "axios";
import { openNotificationWithIcon } from "../../../App";
import { filterData, isTokenValid } from "../../../utils/utils";
import myContext from "../../../state/myContext";

const MusicTemplateEdit = () => {
  const user = JSON.parse(localStorage.getItem("token"));
  const { setShow_login_modal } = useContext(myContext);
  const [is_confirmed, setIs_confirmed] = useState(true);
  const inputRef = useRef();
  const audioRef = useRef();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [audio, setAudio] = useState(null);
  const [audio64, setAudio64] = useState(null);
  const { selected_json, edit_page, selected_pages, deleted_video } =
    useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [selected_url, setSelected_url] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [selected_audio, setSelected_audio] = useState([
    {
      signed_url: "",
      audio_name: "",
    },
  ]);

  const new_complete_json = {
    ...selected_json,
    pages: filterData(
      selected_pages,
      deleted_video?.map((v) => v.deleted)
    ),
    audios: selected_audio,
  };

  const handleMusic = (base64, name) => {
    setLoading1(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + "post-user-file",
      headers: {
        Auth_ID_Token: user.IdToken,
        Access_Token: user.AccessToken,
        File_Name: "test.txt",
        "Content-Type": "application/json",
      },
      data: base64,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading1(false);
        if (response.status === 200 && response.data.statusCode === 200) {
          openNotificationWithIcon(
            "success",
            "Success",
            "Uploaded Successfully"
          );
          setAudio64(response.data.file_url);
        }
        setSelected_audio([
          { signed_url: response.data.file_url, audio_name: name },
        ]);
      })
      .catch((error) => {
        console.log(error);
        setLoading1(false);
        openNotificationWithIcon("error", "Error", error.message);
      });
  };
  const addFile = (e) => {
    if (!isTokenValid()) {
      setShow_login_modal(true);
      return;
    }
    if (e.target.files[0]) {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          handleMusic(reader.result, file.name);
        };
        reader.readAsDataURL(file);
      }
      const fileName = e.target.files[0].name;
      // handleMusic(fileName)
      setAudio({
        url: URL.createObjectURL(e.target.files[0]),
        name: fileName,
      });
    }
  };
  const clearFile = () => {
    setAudio(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const onConfirmexport = async () => {
    if (!isTokenValid()) {
      setShow_login_modal(true);
      setOpen(false);
      return;
    }
    const user = JSON.parse(localStorage.getItem("token"));
    setLoading(true);
    let data = JSON.stringify({
      theme: new_complete_json,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + "post-order",
      headers: {
        Auth_ID_Token: user?.IdToken,
        Access_Token: user?.AccessToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setLoading(false);

      if (
        response.data.statusCode == "200" &&
        response.data.message == "Success"
      ) {
        localStorage.setItem("audio", "true");
        navigate("/");
      } else {
        openNotificationWithIcon("error", "Alert", response.data.messages);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error",
        error?.response?.data?.message
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelected_audio(selected_json.audios[0]);
  }, [selected_json]);

  return (
    <>
      {loading1 ? (
        <PageLoader />
      ) : (
        <div className="flex gap-4 my-8 mx-8 flex-col">
          <div className="flex justify-center gap-5  flex-col w-full max-w-[900px] self-center">
            {/* Input */}
            <div className="max-w-[430px]">
              <p className="font-inter font-medium text-[18px] text-[#071524E5]">
                Studio/Photographer Name*
              </p>
              <input
                placeholder="Display in video"
                className="font-inter text-[#071524E5] text-lg border border-[#E2E5EA] py-2 px-4 outline-none w-full rounded-lg mt-2"
              />
            </div>
            {/*  */}
            <div className="flex gap-5 md:gap-16 flex-col md:flex-row w-full ">
              <div className="flex flex-col gap-3 flex-1">
                <p className="font-inter font-medium text-[18px] text-[#071524E5]">
                  Select & add music
                </p>
                <div className="divide-y flex flex-col border w-auto  ">
                  {selected_json.audios?.map((v, i) => (
                    <div className=" justify-center flex flex-col " key={i}>
                      <RadioBtn
                        active={
                          selected_audio[0]?.audio_name == v?.audio_name
                            ? true
                            : false
                        }
                        title={v?.audio_name}
                        className="flex py-4 px-5 items-center gap-2"
                        onClick={() => setSelected_audio([v])}
                        right={
                          selected_url == v.signed_url ? (
                            <IoMdClose
                              size={25}
                              color="#071524"
                              onClick={() => setSelected_url("")}
                            />
                          ) : (
                            <FaPlay
                              size={25}
                              color="#071524"
                              onClick={() => setSelected_url(v.signed_url)}
                            />
                          )
                        }
                      />
                      {selected_url == v.signed_url && (
                        <audio
                          ref={audioRef}
                          controls
                          disablePictureInPicture
                          controlsList="nodownload noplaybackrate"
                          className=""
                        >
                          <source src={selected_url} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-3 flex-1">
                <p className="font-inter font-medium text-[18px] text-[#071524E5]">
                  Upload music file
                </p>

                {!audio?.name ? (
                  <button
                    onClick={() => inputRef.current.click()}
                    className={`w-auto xl:w-[22rem] border-[1px] border-[#CD8E42] border-dashed h-auto py-4 md:h-[9rem] rounded-[10px] items-center px-[15px] text-[16px] text-[#071524] font-inter font-normal bg-gradient-to-r from-[#FFF0901A] to-[#FABF551A]`}
                  >
                    Select .mp3 music form local
                  </button>
                ) : (
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center flex-row w-full xl:w-[22rem] border-[1px] border-[#CD8E42] h-auto py-3 border-dashed  rounded-[10px] px-[15px] bg-gradient-to-r from-[#FFF0901A] to-[#FABF551A]">
                      <div className="flex min-w-[20px]">
                        <div
                          onClick={() =>
                            setSelected_audio([
                              { signed_url: audio64, audio_name: audio.name },
                            ])
                          }
                          className={`h-[15px] md:min-h-[20px] w-[15px] md:w-[20px] md:min-w-[20px] border-custom-primary rounded-full cursor-pointer ${
                            selected_audio[0]?.audio_name == audio.name
                              ? "border-[4px] md:border-[5px]"
                              : "border-[1.5px] md:border-[2px]"
                          }`}
                        />
                      </div>
                      <div className="font-inter w-full font-normal text-[#071524CC]  text-clamp-base16">
                        {audio.name}
                      </div>

                      <div className="flex">
                        <IoCloseCircleSharp
                          size={25}
                          color="#f00"
                          onClick={clearFile}
                        />
                      </div>
                    </div>
                    <audio
                      ref={audioRef}
                      controls
                      className="w-full"
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate"
                    >
                      <source src={audio?.url} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}

                <input
                  accept=".mp3"
                  type="file"
                  ref={inputRef}
                  onChange={addFile}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-1  items-end justify-end gap-3">
            <Button
              title={"Back"}
              bg={"bg-[#fff]"}
              className={"px-8"}
              onClick={() => {
                navigate(-1);
                store.dispatch(setEdit_Page(edit_page - 1));
              }}
            />
            <Button
              title={"Next"}
              className={"px-8"}
              onClick={() => {
                if (selected_audio[0]?.audio_name?.length == 0) {
                  openNotificationWithIcon(
                    "warning",
                    "Alert",
                    "Please Select audio file"
                  );
                  return;
                }
                audioRef?.current?.pause();
                setSelected_url("");
                setIsOpen(true);
              }}
            />
          </div>
        </div>
      )}

      <Modal
        width={window.innerWidth < 400 ? "auto" : "40%"}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={""}
        centered
        closeIcon={<IoMdClose size={30} color="#fff" className="mt-2" />}
      >
        <div className="flex items-center justify-between ">
          <div className="font-baskerville text-clamp-popup-title text-[#fff]">
            Alert
          </div>
          <div></div>
        </div>
        <div className="mx-6 flex flex-col gap-4 my-4 items-center">
          <div className="h-[5rem] w-[5rem] rounded-full flex items-center justify-center bg-white">
            <FiCheck size={"3rem"} color="#07413E" />
          </div>
          <p className="font-inter font-normal text-[20px] text-center text-[#FFFFFF]">
            Music has been added successfully
          </p>
          <p className="font-inter font-light text-[16px] text-center text-[#FFFFFF]">
            Now you can generate the video and Card’s PDF
          </p>

          <Button
            title={"Generate Video & Card PDF"}
            onClick={() => {
              setIsOpen(false);
              onConfirmexport();
              // setOpen(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        width={window.innerWidth < 400 ? "auto" : "60%"}
        styles={{
          content: {
            backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="flex items-center justify-between ">
          <div className="font-baskerville text-clamp-popup-title text-[#fff]">
            Card PDF Preview
          </div>
          <IoMdClose
            size={30}
            color="#fff"
            onClick={() => setOpen(false)}
            className="cursor-pointer"
          />
        </div>

        {/* <div className="flex-col w-full h-[32rem] rounded-[10px] px-4 py-4 flex bg-[#fff] mt-4">
          <div className="overflow-hidden w-full flex  py-2 gap-3 overflow-x-auto scrollBar-color">
            {/* {selected_theme?.preview_images?.map((v, i) => (
              <WatermarkedImage
                src={v.image_url}
                watermarkSrc={watermark}
                key={i}
              />
            ))} 
            {snap_shot
              ?.filter((v) => v.snap != "")
              ?.map((v, i) => (
                <WatermarkedImage
                  src={v.snap}
                  watermarkSrc={watermark}
                  key={i}
                />
              ))}
          </div>
        </div> */}
        <div className="confirm-shadow">
          <Confirm className="h-auto flex w-[200px] self-center mx-auto" />
        </div>
        <p className="font-inter flex text-[20px] text-[#fff]">
          Please ensure all details are correct before proceeding, Once
          confirmed, you won’t be able to make any further edits.
        </p>
        <p className="font-inter text-[16px] text-[#fff]">
          If you need to make changes, click the{" "}
          <span className="text-[#FF9500] mx-1"> Edit </span> button to go back
          and update your information.
        </p>
        <p className="font-inter font-normal text-[16px] text-[#fff]">
          If everything looks good, click on{" "}
          <span className="text-[#FF9500] mx-1"> Confirm </span> to generate
          your invitation.
        </p>

        <div className="mt-4 flex flex-col md:flex-row items-center gap-4">
          <div className="checkbox-wrapper-65">
            <label htmlFor="cbk1-65" className="">
              <span className="font-inter ml-2 text-[16px] font-light leading-[20px] text-[#fff]">
                <input
                  type="checkbox"
                  id="cbk1-65"
                  checked={is_confirmed}
                  onChange={() => setIs_confirmed(!is_confirmed)}
                />
                <span className="cbx">
                  <svg width="12px" height="11px" viewBox="0 0 12 11">
                    <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
                  </svg>
                </span>
                {"  "}
                Confirm and processed after confirming you won’t be able to edit
                any information
              </span>
            </label>
          </div>

          <div className="flex flex-1  items-end justify-end gap-3">
            <Button
              title={"Edit"}
              bg={"bg-[#fff]"}
              className={"px-8"}
              onClick={() => {
                store.dispatch(setEdit_Page(0));
                store.dispatch(setEvent_Page(1));
                navigate(
                  "/template/" + selected_json?.themeName + "/edit/cover-page"
                );
              }}
            />
            <Button
              title={"Confirm"}
              className={"px-8"}
              onClick={() => {
                if (!is_confirmed) {
                  openNotificationWithIcon(
                    "info",
                    "Alert",
                    "Please select check condition"
                  );
                  return;
                }
                onConfirmexport();
                // setIsOpen1(false);
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        width={"100%"}
        // height={"100vh"}
        styles={{
          content: {
            backgroundColor: "transparent",
            // backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
          },
          header: { backgroundColor: "transparent" },
        }}
        title={""}
        open={loading}
        // onCancel={() => setOpen1(false)}
        footer={null}
        centered
        closeIcon={null}
      >
        <div className="h-full w-screen items-center justify-center ">
          <PageLoader />
        </div>
      </Modal>
    </>
  );
};

export default MusicTemplateEdit;
