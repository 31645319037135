import React, { useContext, useEffect, useState } from "react";
import {
  HeadingText,
  PageLoader,
  RadioBtn,
  TitleText,
} from "../utils/layoutUtils";
import CategoryBtn from "../components/CategoryBtn";
import { features } from "../utils/data";
import HomeCard from "../components/HomeCard";
import Pagination from "../components/Pagination";
import FeaturesCard from "../components/FeaturesCard";
import ModalComponent from "../components/ModalComponent";
import HomeSlider from "../components/HomeSlider";
import myContext from "../state/myContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../redux/store";
import { setSave_the_date } from "../redux/slice/userSlice";

const HomePage = () => {
  const location = useLocation();
  console.log(location.state);

  const {
    home_theme,
    get_home_theme_list,
    category_data,
    selected_cat,
    setSelected_cat,
    show_banner,
    setShow_banner,
    activeTab,
  } = useContext(myContext);
  const { save_the_date } = useSelector((state) => state.user);
  const [active, setActive] = useState(1);
  const navigate = useNavigate();

  const HandleClick = (title, item) => {
    navigate("/template/" + title, {
      state: { cat: activeTab, s_cat: selected_cat, item: item },
    });
  };

  useEffect(() => {
    save_the_date
      ? get_home_theme_list(activeTab, selected_cat, true)
      : get_home_theme_list(activeTab, selected_cat, false);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("audio") == "true") {
      localStorage.removeItem("audio");
      navigate("/my-order");
    }
  }, [location]);

  const ITEMS_PER_PAGE = 8;

  const startIndex = (active - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = home_theme?.list?.themes?.slice(startIndex, endIndex);

  return (
    <div className="relative flex justify-center flex-col">
      <div className="flex max-w-[95%] xl:max-w-[80%] flex-col self-center w-full">
        <HeadingText className={"mt-5"}>
          {activeTab == "Wedding"
            ? "Create Stunning Digital Invitations for Every Occasion"
            : activeTab == "Engagement"
            ? "Join us in celebrating Festival/Ritual with joy and togetherness!"
            : activeTab == "Valentine's Week"
            ? "Celebrate Love Every Day with Our Valentine's Week Card!"
            : activeTab == "Birthday"
            ? "Celebrate Every Birthday with a Personalized Invitation!"
            : "Create Stunning Digital Invitations for Every Occasion"}
        </HeadingText>
        <div
          className={`font-inter font-normal text-custom-text text-center text-[20px] mt-4`}
        >
          Just enter text &{" "}
          <span className="text-[#FF9500] font-bold">
            your wedding invitation card video & card PDF
          </span>{" "}
          is ready in one go.
        </div>
        {/* <TitleText className={"mt-4"}>
          {activeTab == "Wedding"
            ? "Design beautiful invitation videos and card PDFs effortlessly with our easy-to-use platform."
            : activeTab == "Engagement"
            ? "Come and be a part of our Festival/Ritual celebration. Let's create beautiful memories together as we honor this special occasion with love and happiness"
            : activeTab == "Valentine's Week"
            ? "Valentine's Week is a time to express love and affection in many different ways. Make each day special with personalized invitations that capture the essence of romance and joy. Whether it's for a party, a romantic dinner, or a special event, we have the perfect templates for you."
            : activeTab == "Birthday"
            ? "Make every birthday celebration unforgettable with our wide range of customizable birthday invitation templates. Whether it's a milestone birthday or a fun-filled party for kids, we have the perfect design for you."
            : "Design beautiful invitation videos and card PDFs effortlessly with our easy-to-use platform."}
        </TitleText> */}
        {category_data?.loading && !home_theme?.loading ? (
          <PageLoader />
        ) : (
          <div className="flex overflow-auto gap-2 md:gap-4 py-4 lg:flex-wrap lg:justify-center">
            {category_data?.list[activeTab]?.map((item, index) => (
              <CategoryBtn
                onClick={() => {
                  setSelected_cat(item);
                  get_home_theme_list(activeTab, item, save_the_date);
                }}
                key={index}
                title={item}
                active={item == selected_cat ? true : false}
              />
            ))}
          </div>
        )}

        {!category_data?.loading && home_theme?.loading ? (
          <PageLoader />
        ) : (
          <>
            <div className="flex gap-4 md:gap-8 lg:gap-10 items-center justify-center mt-4 mb-6 ">
              <RadioBtn
                title={"Wedding all events"}
                onClick={() => {
                  store.dispatch(setSave_the_date(false));
                  get_home_theme_list(activeTab, selected_cat, false);
                }}
                active={
                  save_the_date == false || save_the_date == undefined
                    ? true
                    : false
                }
              />
              <RadioBtn
                title={"Save the date"}
                onClick={() => {
                  store.dispatch(setSave_the_date(true));
                  get_home_theme_list(activeTab, selected_cat, true);
                }}
                active={save_the_date == true ? true : false}
              />
            </div>

            {currentItems?.length == 0 ? (
              <HeadingText>Themes are loading...</HeadingText>
            ) : (
              <div className="container mx-auto">
                <div className="grid  grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                  {currentItems?.map((item, index) => (
                    <div className=" flex text-white" key={index}>
                      <HomeCard
                        HandleClick={() => HandleClick(item?.themeName, item)}
                        url={item.cover_image_url}
                        title={item?.themeName}
                        // price={"Just ₹" + item.price}
                        price={item.price}
                        realPrice={"₹" + item.discount}
                        active={index == 0 ? true : false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {home_theme?.list?.themes?.length > ITEMS_PER_PAGE && (
          <Pagination
            setActive={setActive}
            active={active}
            length={home_theme?.list?.themes?.length}
            ITEMS_PER_PAGE={ITEMS_PER_PAGE}
          />
        )}

        <div className="mt-4 w-full flex flex-col p-clamp-md border-[1px] border-[#0715241A] rounded-md md:rounded-[20px] shadow-xl shadow-[#91B4E426]">
          <HeadingText>How It Works</HeadingText>
          {/* <TitleText className={`text-clamp-base20 mt-4 md:mt-8`}>
            Creating your perfect invitation is easy and fun! Just follow these
            six simple steps
          </TitleText> */}
          <p
            className={`font-inter font-normal text-custom-text text-center text-clamp-base20 md:mt-4`}
          >
            Creating your perfect invitation is easy and fun! Just follow these
            six simple steps
          </p>
        </div>
        {/* <div className="flex flex-wrap gap-2 md:gap-4 xl:gap-8 justify-center mt-2 md:mt-8">
          {features.map((item, index) => (
            <FeaturesCard key={index} item={item} />
          ))}
        </div> */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-4 xl:gap-8 justify-center mt-4 md:mt-8 px-4 md:px-10">
          {features.map((item, index) => (
            <FeaturesCard key={index} item={item} />
          ))}
        </div>
        <p
          className={`font-inter font-normal text-custom-text text-center text-clamp-base20 mt-4 md:mt-8`}
        >
          Ready to create your invitation? Get started now and make your event
          unforgettable!
        </p>
        <button
          // onClick={() => window.open(banner_list?.list[active].link)}
          onClick={() => {}}
          className={`self-center mt-4 md:mt-8 bg-custom-green h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-clamp-x-slider-button text-clamp-base18 text-[#fff] font-inter font-medium`}
        >
          Get Started
        </button>
      </div>

      <ModalComponent
        isOpen={show_banner}
        setIsOpen={setShow_banner}
        onClose={() => setShow_banner(false)}
      >
        <HomeSlider onClick={() => setShow_banner(false)} />
      </ModalComponent>
    </div>
  );
};

export default HomePage;
