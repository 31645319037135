import React, { useState } from "react";
import { Button, Modal } from "antd";
import { IoMdClose } from "react-icons/io";

const CustomModal = ({
  isModalOpen = false,
  setIsModalOpen,
  children,
  heading,
  footer,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      width={window.innerWidth < 400 ? "90%" : "350px"}
      styles={{
        content: {
          backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
          // padding: 50,
        },
        header: { backgroundColor: "transparent" },
      }}
      title={
        <div className="font-baskerville text-clamp-popup-title text-[#fff]">
          {heading}
        </div>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={footer || null}
      centered
      closeIcon={<IoMdClose size={20} color="#fff" />}
    >
      {children}
    </Modal>
  );
};
export default CustomModal;
