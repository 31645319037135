import React, { useEffect, useRef, useState } from "react";

const SnapShot = ({ jsonData = {}, sections, canvasRef }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext("2d");
    const originalWidth = 1080;
    const originalHeight = 1920;
    const aspectRatio = originalWidth / originalHeight;

    const resizeCanvas = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let newWidth, newHeight;

      if (windowWidth / windowHeight > aspectRatio) {
        newHeight = windowHeight - 200;
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = windowWidth - 100;
        newHeight = newWidth / aspectRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
    };

    const loadFonts = async (sections) => {
      const fontPromises = sections.map(async (section) => {
        const fontFace = new FontFace(
          section.fontName,
          `url(${section.fontFile})`
        );
        const loadedFont = await fontFace.load();
        document.fonts.add(loadedFont);
      });
      await Promise.all(fontPromises);
    };

    const renderContentOnCanvas = () => {
      context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw video

      // Draw text
      sections.forEach((section) => {
        const fontSize = (canvas.width * section.fontSize) / 1080;
        context.font = `${fontSize}px ${section.fontName}`;
        context.fillStyle = section.fontColor;
        const text = section.text || section.placeholder;
        const textWidth = context.measureText(text).width;
        const dynamicLeft = (canvas.width - textWidth) / 2;
        const dynamicTop = (canvas.height * section.placementY) / 1920;

        context.fillText(text, dynamicLeft, dynamicTop);
      });

      requestAnimationFrame(renderContentOnCanvas);
    };

    const initialize = async () => {
      await loadFonts(sections);
      resizeCanvas();
      renderContentOnCanvas();
    };

    window.addEventListener("resize", resizeCanvas);

    video.addEventListener("loadeddata", () => {
      video.play();
      initialize();
    });

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      video.removeEventListener("loadeddata", initialize); // Cleanup
    };
  }, [jsonData, sections]);

  return (
    <div style={{ position: "relative", display: "none" }}>
      <video
        ref={videoRef}
        src={jsonData.pageVideo}
        style={{ display: "none" }}
        muted
        autoPlay
        loop
        crossOrigin="anonymous"
      />
      <canvas
        ref={canvasRef}
        style={{ borderRadius: 10, position: "relative" }}
      />
    </div>
  );
};

export default SnapShot;

// import React, { useEffect, useRef, useState } from "react";

// const SnapShot = ({
//   jsonData = {},
//   sections,
//   setSections,
//   imageBoxes,
//   setImageBoxes,
// }) => {
//   const canvasRef = useRef(null);
//   const videoRef = useRef(null);
//   const [fontsLoaded, setFontsLoaded] = useState(false);

//   const updateJsonWithPlaceholders = (jsonData) => {
//     if (!jsonData) return null;
//     return jsonData
//       .filter((section) => section.sectionType === "text")
//       .map((section) =>
//         section.placeholder
//           ? { ...section }
//           : {
//               ...section,
//               text: "",
//               placeholder: section.text,
//             }
//       );
//   };

//   useEffect(() => {
//     setSections(updateJsonWithPlaceholders(jsonData?.sections));
//     const newImageBoxes = jsonData?.sections?.filter(
//       (section) => section.sectionType === "image"
//     );
//     setImageBoxes(newImageBoxes);
//   }, [jsonData]);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const video = videoRef.current;

//     if (!canvas || !video) return;

//     const context = canvas.getContext("2d");
//     const originalWidth = 1080;
//     const originalHeight = 1920;
//     const aspectRatio = originalWidth / originalHeight;

//     const resizeCanvas = () => {
//       const windowWidth = window.innerWidth;
//       const windowHeight = window.innerHeight;

//       let newWidth, newHeight;

//       if (windowWidth / windowHeight > aspectRatio) {
//         newHeight = windowHeight - 200;
//         newWidth = newHeight * aspectRatio;
//       } else {
//         newWidth = windowWidth - 40;
//         newHeight = newWidth / aspectRatio;
//       }

//       canvas.width = newWidth;
//       canvas.height = newHeight;
//     };

//     const drawCanvas = () => {
//       if (context && canvas && fontsLoaded) {
//         context.clearRect(0, 0, canvas.width, canvas.height);
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         drawImagesOnCanvas(context);
//         drawTextOnCanvas(context);
//       }
//     };

//     resizeCanvas();
//     window.addEventListener("resize", resizeCanvas);

//     const handleCanPlay = () => {
//       video.play().catch((error) => {
//         console.error("Error playing video:", error);
//       });
//       drawCanvas();
//     };

//     video.addEventListener("canplay", handleCanPlay);
//     video.addEventListener("error", (e) => {
//       console.error("Error loading video:", e);
//     });

//     return () => {
//       window.removeEventListener("resize", resizeCanvas);
//       video.removeEventListener("canplay", handleCanPlay);
//     };
//   }, [jsonData, sections, imageBoxes, fontsLoaded]);

//   const calculateTextWidth = (text, font) => {
//     const canvas = canvasRef.current;
//     const ctx = canvas?.getContext("2d");

//     if (!ctx) {
//       console.error("Failed to get canvas context");
//       return 0;
//     }

//     ctx.font = font;
//     return ctx.measureText(text).width;
//   };

//   const loadFont = async (fontName, fontFile) => {
//     try {
//       const fontFace = new FontFace(fontName, `url(${fontFile})`);
//       await fontFace.load();
//       document.fonts.add(fontFace);
//       console.log(`Font loaded: ${fontName}`);
//     } catch (err) {
//       console.error("Failed to load font:", err);
//     }
//   };

//   const drawTextOnCanvas = (context) => {
//     sections?.forEach((section, index) => {
//       if (!canvasRef.current) return;

//       const originalWidth = 1080;
//       const originalHeight = 1920;
//       const aspectRatio = originalWidth / originalHeight;
//       const windowWidth = window.innerWidth;
//       const windowHeight = window.innerHeight;

//       let newWidth, newHeight;

//       if (windowWidth / windowHeight > aspectRatio) {
//         newHeight = windowHeight - 320;
//         newWidth = newHeight * aspectRatio;
//       } else {
//         newWidth = windowWidth - 150;
//         newHeight = newWidth / aspectRatio;
//       }

//       const canvasRect = canvasRef.current.getBoundingClientRect();
//       const fontSize = (newWidth * section.fontSize) / 1080;

//       const textWidth = calculateTextWidth(
//         section.text?.length > 0 ? section.text : section?.placeholder,
//         `${fontSize}px '${section.fontName}'`
//       );

//       const buffer = 25;
//       const maxTextWidth = canvasRect.width - buffer;

//       const finalTextWidth =
//         textWidth + buffer > maxTextWidth ? maxTextWidth : textWidth + buffer;

//       const dynamicLeft = (canvasRect.width - finalTextWidth) / 2;
//       const dynamicTop = (canvasRect.height * section.placementY) / 1920;

//       context.font = `${fontSize}px '${section.fontName}'`;
//       context.textAlign = "center";
//       context.textBaseline = "middle";
//       context.fillStyle = section.fontColor;

//       context.fillText(
//         section.text?.length > 0 ? section.text : section?.placeholder,
//         dynamicLeft + finalTextWidth / 2,
//         dynamicTop + fontSize / 2
//       );

//       // if (focusedIndex === index) {
//       //   context.strokeStyle = focusColor;
//       //   context.lineWidth = 1;
//       //   context.strokeRect(
//       //     dynamicLeft,
//       //     dynamicTop - fontSize / 2,
//       //     finalTextWidth,
//       //     fontSize
//       //   );
//       // }
//     });
//   };

//   const drawImagesOnCanvas = (context) => {
//     imageBoxes?.forEach((section) => {
//       const image = new Image();
//       image.src = section.imageSrc;

//       image.onload = () => {
//         if (!canvasRef.current) return;

//         const originalWidth = 1080;
//         const originalHeight = 1920;
//         const aspectRatio = originalWidth / originalHeight;
//         const windowWidth = window.innerWidth;
//         const windowHeight = window.innerHeight;

//         let newWidth, newHeight;

//         if (windowWidth / windowHeight > aspectRatio) {
//           newHeight = windowHeight - 200;
//           newWidth = newHeight * aspectRatio;
//         } else {
//           newWidth = windowWidth - 100;
//           newHeight = newWidth / aspectRatio;
//         }

//         const canvasRect = canvasRef.current.getBoundingClientRect();
//         const finalWidth = (newWidth * section.width) / 1080;
//         const finalHeight = (newHeight * section.height) / 1920;

//         const dynamicLeft = (newWidth - finalWidth) / 2;
//         const dynamicTop = (canvasRect.height * section.placementY) / 1920;

//         context.drawImage(
//           image,
//           dynamicLeft,
//           dynamicTop,
//           finalWidth,
//           finalHeight
//         );
//       };

//       image.onerror = () => {
//         console.error(`Failed to load image: ${section.imageSrc}`);
//       };
//     });
//   };

//   useEffect(() => {
//     const loadFonts = async () => {
//       const fontPromises = sections
//         .filter((section) => section.fontFile)
//         .map((section) => loadFont(section.fontName, section.fontFile));
//       await Promise.all(fontPromises);
//       setFontsLoaded(true);
//     };

//     loadFonts();
//   }, [sections]);

//   return (
//     <div style={{ position: "relative" }}>
//       <video
//         ref={videoRef}
//         src={jsonData.pageVideo}
//         style={{ display: "none" }}
//         muted
//         autoPlay
//         loop
//       />
//       <canvas
//         ref={canvasRef}
//         style={{
//           borderRadius: 10,
//           position: "relative",
//           width: "100%",
//           height: "auto",
//         }}
//       />
//     </div>
//   );
// };

// export default SnapShot;
