import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../utils/layoutUtils";
import { useNavigate } from "react-router-dom";
import CoverEdit from "../../template/templateEdit/CoverEdit";
import EventDeletePopup from "../../../components/EventDeletePopup";
import { useSelector } from "react-redux";
import { store } from "../../../redux/store";
import {
  setDeleted_video,
  setEdit_Page,
  setJson_Data,
  setSelected_Pages,
  setSnap_shot,
} from "../../../redux/slice/userSlice";

import SnapShot from "../../../components/SnapShot";

const CoverTemplateEdit = () => {
  const canvasRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const {
    selected_pages,
    selected_json,
    edit_page,
    snap_shot,
    deleted_video,
    updated_pages,
    save_the_date,
  } = useSelector((state) => state.user);
  const [sections, setSections] = useState([]);
  const [imageBoxes, setImageBoxes] = useState([]);
  const [complete_json, setComplete_json] = useState({});

  const handleSnapshot = () => {
    const updatedSnapShot = [...snap_shot];

    if (deleted_video[edit_page]?.deleted) {
      updatedSnapShot[edit_page] = { snap: "" };
      store.dispatch(setSnap_shot(updatedSnapShot));
      // store.dispatch(setSnap_shot(updatedSnapShot.splice(edit_page, 1)));
      return;
    }
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png");

    updatedSnapShot[edit_page] = { snap: dataURL };

    store.dispatch(setSnap_shot(updatedSnapShot));
  };

  const onNext = () => {
    // handleSnapshot();
    // const filter_section = sections.map((section) => {
    //   if (section.sectionType === "text") {
    //     // Remove placeholder after updating text
    //     // delete section.placeholder;
    //   }
    //   return section;
    // });
    const filter_section = sections.map((section) => {
      if (section.text === "") {
        section.text = section.placeholder;
      }
      return section;
    });

    const complete_section = filter_section.concat(imageBoxes);
    const new_complete_json = {
      ...complete_json,
      pages: [
        {
          ...complete_json.pages[0],
          sections: complete_section,
        },
        ...complete_json.pages.slice(1), // Include any other pages if present
      ],
    };

    // Dispatch the new JSON to the store
    store.dispatch(setSelected_Pages(new_complete_json.pages));
    store.dispatch(setEdit_Page(edit_page + 1));
    store.dispatch(setJson_Data(new_complete_json));
    save_the_date
      ? navigate("/template/" + selected_json?.themeName + "/edit/add-music")
      : navigate(
          "/template/" + selected_json?.themeName + "/edit/family-details"
        );
  };

  useEffect(() => {
    setComplete_json(selected_json);
  }, [selected_json]); // Adding dependency to ensure it runs when selected_json changes

  const restore_page = () => {
    const new_page = deleted_video.map((page, index) => {
      if (index === edit_page) {
        return {
          ...page,
          deleted: !deleted_video[edit_page]?.deleted,
        };
      }
      return page;
    });
    store.dispatch(setDeleted_video(new_page));
  };

  return (
    <>
      <div className="flex gap-4 my-6 mx-4 flex-col xl:flex-row">
        <div className="flex-1 items-end hidden xl:flex">
          {deleted_video[edit_page]?.deleted || (
            <Button
              title={"Remove cover page"}
              bg={"bg-[#fff]"}
              className={"text-[#FF0000] border-[#FF0000] px-10"}
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
        <div className="flex justify-center">
          {deleted_video[edit_page]?.deleted ? (
            <div className="flex flex-col items-center justify-center gap-3 my-20">
              <h1 className="text-center font-baskerville font-semibold text-clamp-heading text-[#071524]">
                This page has been deleted
              </h1>
              <Button title={"Restore the page"} onClick={restore_page} />
            </div>
          ) : (
            <div className="flex flex-col">
              <SnapShot
                jsonData={selected_pages[0]}
                sections={sections}
                imageBoxes={imageBoxes}
                canvasRef={canvasRef}
                setSections={setSections}
                setImageBoxes={setImageBoxes}
              />

              <CoverEdit
                jsonData={selected_pages[0]}
                sections={sections}
                setSections={setSections}
                imageBoxes={imageBoxes}
                setImageBoxes={setImageBoxes}
              />
            </div>
          )}
        </div>

        {deleted_video[edit_page]?.deleted || (
          <div className="flex-1 items-end flex xl:hidden">
            <Button
              title={"Remove cover page"}
              bg={"bg-[#fff]"}
              className={"text-[#FF0000] border-[#FF0000] px-10"}
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        )}
        <div className="flex flex-1  items-end justify-between xl:justify-end gap-3">
          <Button
            title={"Back"}
            bg={"bg-[#fff]"}
            className={"px-8"}
            onClick={() => {
              store.dispatch(setEdit_Page(edit_page - 1));
              navigate(-1);
            }}
          />
          <Button
            title={"Next"}
            className={"px-8"}
            onClick={() => {
              // if (
              //   !deleted_video[edit_page]?.deleted &&
              //   sections?.filter((v) => v.text == "").length > 0
              // ) {
              //   alert("Please fill all fields");
              //   return;
              // }
              onNext();
            }}
          />
        </div>
      </div>
      <EventDeletePopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default CoverTemplateEdit;
