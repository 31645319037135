import { Modal } from "antd";
import React from "react";
import { Button, MediumText } from "../utils/layoutUtils";
import { FaTrash } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { store } from "../redux/store";
import { setDeleted_video } from "../redux/slice/userSlice";

const EventDeletePopup = ({ isOpen, setIsOpen }) => {
  const { edit_page, deleted_video, event_page } = useSelector(
    (state) => state.user
  );
  return (
    <Modal
      width={window.innerWidth < 400 ? "90%" : "35%"}
      styles={{
        content: {
          backgroundImage: "linear-gradient(to bottom, #033A37, #1E635F)",
        },
        header: { backgroundColor: "transparent" },
      }}
      title={""}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={""}
      centered
      closeIcon={<></>}
    >
      <div className="flex items-center justify-between ">
        <div className="font-baskerville text-clamp-popup-title text-[#fff]">
          Confirm
        </div>
        <IoMdClose size={20} color="#fff" />
      </div>
      <div className="flex items-center justify-center my-4">
        <div className="bg-[#02BFA1] h-[70px] w-[70px] rounded-full flex items-center justify-center">
          <FaTrash size={35} color="#fff" />
        </div>
      </div>
      <MediumText className={"text-center mt-3 px-2 md:px-16"}>
        Are you sure you wanted to delete this event in your wedding card &
        Video
      </MediumText>
      <div className="flex items-center justify-center gap-5 mt-5 mb-2">
        <Button
          title={"No"}
          bg={"bg-[#fff]"}
          className={"w-[75px]"}
          onClick={() => setIsOpen(false)}
        />
        <Button
          title={"Yes"}
          className={"w-[75px]"}
          onClick={() => {
            const new_page = deleted_video.map((page, index) => {
              if (index === edit_page + event_page - 1) {
                return {
                  ...page,
                  deleted: !deleted_video[edit_page + event_page - 1]?.deleted,
                };
              }
              return page;
            });
            store.dispatch(setDeleted_video(new_page));
            setIsOpen(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default EventDeletePopup;
