import React from "react";

const RefundPolicy = () => {
  return (
    <div className="max-w-[90%] md:max-w-[80%] w-full  mx-auto flex">
      <div className="font-inter text-[#071524] p-4  w-full block">
        <h1 className="text-2xl font-bold font-baskerville mb-4">
          Refund Policy
        </h1>
        <p className="mb-4">
          At <strong>Invitecard.me</strong>, we strive to provide high-quality
          digital products and services that meet your expectations. However, we
          understand that there may be occasions when you may require a refund.
          Please review our refund policy below.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">
          Eligibility for Refunds
        </h2>
        <h3 className="text-lg font-medium mt-2 mb-1">Digital Products:</h3>
        <p className="mb-4">
          Due to the nature of our digital products, we do not offer refunds
          once a digital invitation or card has been created and downloaded.
          This is because digital products cannot be "returned" in the same way
          physical products can.
        </p>

        <h3 className="text-lg font-medium mt-2 mb-1">Technical Issues:</h3>
        <p className="mb-4">
          If you experience any technical issues with our platform that result
          in a defective or unusable product (e.g., unable to download the
          product, corrupted file), please contact us within 7 days of your
          purchase. We will make every effort to resolve the issue or offer a
          replacement. If the issue cannot be resolved, a refund may be issued
          at our discretion.
        </p>

        <h3 className="text-lg font-medium mt-2 mb-1">Accidental Purchases:</h3>
        <p className="mb-4">
          Refunds for accidental purchases or wrong selections of templates are
          not typically issued, as we provide clear instructions and previews
          during the creation process. Please ensure you review all details
          before completing your purchase.
        </p>

        <h3 className="text-lg font-medium mt-2 mb-1">Custom Orders:</h3>
        <p className="mb-4">
          Refunds for custom orders (e.g., personalized designs created
          specifically for you) are not available once the design process has
          begun, as significant time and resources are invested in creating your
          custom content.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">
          Process for Requesting a Refund
        </h2>
        <p className="mb-4">
          To request a refund, please contact our Customer Support Team at{" "}
          <a
            href="mailto:support@invitecard.me"
            className="text-blue-500 underline"
          >
            support@invitecard.me
          </a>{" "}
          within 7 days of your purchase. Include:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Your order number.</li>
          <li>A detailed description of the issue.</li>
          <li>Screenshots or other supporting documentation if applicable.</li>
        </ul>
        <p className="mb-4">
          Our support team will review your request and respond within 3-5
          business days. If a refund is approved, the funds will be credited
          back to your original method of payment. Please allow 7-10 business
          days for the refund to be processed.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">
          Non-Refundable Situations
        </h2>
        <ul className="list-disc ml-6 mb-4">
          <li>Change of mind after purchase.</li>
          <li>
            Issues arising from incorrect details provided by the user during
            the customization process.
          </li>
          <li>Expired access to download links.</li>
        </ul>

        <h2 className="text-xl font-medium mt-4 mb-2">Contact Information</h2>
        <p className="mb-4">
          If you have any questions about our Refund Policy, feel free to
          contact us at:
        </p>
        <p className="mb-4">
          <strong>Email:</strong>{" "}
          <a
            href="mailto:support@invitecard.me"
            className="text-blue-500 underline"
          >
            support@invitecard.me
          </a>
          <br />
          <strong>Phone:</strong> +91 94797 93733
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
