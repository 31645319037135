import React from "react";

const CategoryBtn = ({ title, active, onClick }) => {
  return (
    // h-[50px]
    <button
      onClick={onClick}
      className={` ${
        active ? "bg-gradient-to-r from-[#FFF090] to-[#FABF55]" : "bg-[#fff]"
      } border-[1px] border-[#CD8E42]  rounded-full items-center px-clamp-x-md py-clamp-y-md whitespace-nowrap text-clamp-base16 text-[#07152499] font-inter font-normal hover:bg-gradient-to-r from-[#FFF090] to-[#FABF55] transition-[3s]`}
    >
      {title}
    </button>
  );
};

export default CategoryBtn;
