import React, { useRef, useEffect, useState } from "react";

const VideoThumbnail = ({ videoUrl }) => {
  const [thumbnail, setThumbnail] = useState("");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext("2d");
    const originalWidth = 1080; // Example original dimensions
    const originalHeight = 1920;
    const aspectRatio = originalWidth / originalHeight;

    const resizeCanvas = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let newWidth, newHeight;

      if (windowWidth / windowHeight > aspectRatio) {
        newHeight = windowHeight - 200;
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = windowWidth - 40;
        newHeight = newWidth / aspectRatio;
      }

      canvas.width = newWidth * 4; // Increase canvas resolution for better quality
      canvas.height = newHeight * 4; // Increase canvas resolution for better quality
    };

    const renderVideoOnCanvas = () => {
      if (video.readyState >= 2) {
        // Ensure video is loaded
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Create a temporary canvas to downscale the image for the thumbnail
        const thumbnailCanvas = document.createElement("canvas");
        const thumbnailContext = thumbnailCanvas.getContext("2d");
        thumbnailCanvas.width = canvas.width / 2;
        thumbnailCanvas.height = canvas.height / 2;

        thumbnailContext.imageSmoothingEnabled = true;
        thumbnailContext.imageSmoothingQuality = "high";

        // Draw the high-resolution image onto the smaller canvas
        thumbnailContext.drawImage(
          canvas,
          0,
          0,
          thumbnailCanvas.width,
          thumbnailCanvas.height
        );

        // Convert thumbnail canvas to data URL
        setThumbnail(thumbnailCanvas.toDataURL("image/png"));
      }
      requestAnimationFrame(renderVideoOnCanvas);
    };

    // Resize the canvas to fit the screen
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    // Start rendering video on canvas when video is loaded
    video.addEventListener("loadeddata", () => {
      video.currentTime = 1; // Seek to 1 second
    });

    video.addEventListener("seeked", () => {
      renderVideoOnCanvas();
    });

    // Clean up event listeners
    return () => {
      window.removeEventListener("resize", resizeCanvas);
      video.removeEventListener("loadeddata", () => {
        video.currentTime = 1; // Seek to 1 second
      });
      video.removeEventListener("seeked", () => {
        renderVideoOnCanvas();
      });
    };
  }, [videoUrl]); // Depend on videoUrl to handle updates

  return (
    <div>
      <video
        ref={videoRef}
        src={videoUrl}
        crossOrigin="anonymous"
        style={{ display: "none" }} // Hide the video element
      />
      <canvas ref={canvasRef} style={{ display: "none" }} />{" "}
      {/* Hide the canvas element */}
      {thumbnail && <img src={thumbnail} alt="Video Thumbnail" />}
    </div>
  );
};

export default VideoThumbnail;
