import React from "react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const Pagination = ({ active = 1, setActive, length = 0, ITEMS_PER_PAGE }) => {
  const totalPages = Math.ceil(Number(length) / ITEMS_PER_PAGE);

  return (
    <div className="flex items-center justify-center gap-4 my-3">
      <GoArrowLeft
        size={25}
        color={active == 1 ? "#d1d1d1" : "#071524"}
        className={`${active == 1 ? "cursor-not-allowed" : "cursor-pointer"}`}
        onClick={() => active == 1 || setActive(active - 1)}
      />
      {Array(totalPages)
        .fill("")
        .map((v, i) => (
          <button
            key={i}
            onClick={() => setActive(i + 1)}
            className={`${
              active == i + 1
                ? "bg-gradient-to-r from-[#FFF090] to-[#FABF55]"
                : "bg-[#fff] border-[#ECF1F8]"
            } shadow-2xl shadow-[#91B4E426]  border-[1px] border-[#CD8E42] h-[40px] w-[40px] rounded-full flex items-center px-[15px] text-[14px] text-[#071524] font-inter font-normal justify-center `}
          >
            {i + 1}
          </button>
        ))}
      <GoArrowRight
        size={25}
        color={active == totalPages ? "#d1d1d1" : "#071524"}
        className={`${
          active == totalPages ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => active == totalPages || setActive(active + 1)}
      />
    </div>
  );
};

export default Pagination;
