import React, { useState } from "react";
import { Button } from "../utils/layoutUtils";
import crown from "../assets/crown.png";

const HomeCard = ({ title, realPrice, price, url = "", HandleClick }) => {
  const [active, setActive] = useState(false);
  const originalWidth = 1080;
  const originalHeight = 1920;
  const aspectRatio = originalWidth / originalHeight;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let newWidth, newHeight;

  if (windowWidth / windowHeight > aspectRatio) {
    newHeight = windowHeight;
    newWidth = newHeight * aspectRatio;
  } else {
    newWidth = windowWidth;
    newHeight = newWidth / aspectRatio;
  }

  return (
    <>
      <div
        className={`w-full hidden xl:flex flex-col mb-4 relative`}
        // onMouseEnter={() => setActive(true)}
        // onMouseLeave={() => setActive(false)}
      >
        {price == "Premium" && (
          <div className="absolute top-2 left-2 bg-[#071524b3] rounded-[10px] py-2 px-4 z-10 flex gap-2 items-center">
            <img src={crown} className="h-6 w-6 object-contain" />
            <p className="font-inter text-[16px] font-semibold text-[#FFFFFF] ">
              Premium
            </p>
          </div>
        )}
        <div className="w-full h-auto relative">
          <div
            //   ${
            //   active ? "flex" : "hidden"
            // }
            className={`w-full h-full absolute items-center justify-center bg-[#00000066] flex 
            `}
          >
            <Button title="View & Edit Invitation" onClick={HandleClick} />
          </div>
          <img src={url} className="w-full h-full object-fill" />
        </div>
        <div
          className={`font-inter font-normal text-custom-text text-clamp-base18 md:mt-2 `}
        >
          {title}
        </div>
        {/* <div
          className={`font-inter font-normal text-custom-text text-clamp-base16 md:mt-2`}
        >
          {price}
          {/* <span className="line-through ml-2">{realPrice}</span> *
        </div> */}
      </div>
      <div className={`w-full flex xl:hidden flex-col mb-4 relative`}>
        {price == "Premium" && (
          <div className="absolute top-2 left-2 bg-[#071524b3] rounded-[10px] py-2 px-4 z-10 flex gap-2 items-center">
            <img src={crown} className="h-6 w-6 object-contain" />
            <p className="font-inter text-[16px] font-semibold text-[#FFFFFF] ">
              Premium
            </p>
          </div>
        )}
        <div className="w-full h-auto relative">
          <div
            className={`w-full h-full absolute items-center justify-center bg-[#00000066] flex`}
          >
            <Button
              title={
                window.innerWidth > 500
                  ? "View & Edit Invitation"
                  : "View & Edit"
              }
              onClick={HandleClick}
            />
          </div>
          <img src={url} className="w-full h-full object-fill" />
        </div>
        <div
          className={`font-inter font-normal text-custom-text text-clamp-base18 md:mt-2 `}
        >
          {title}
        </div>
        {/* <div
          className={`font-inter font-normal text-custom-text text-clamp-base16 md:mt-2`}
        >
          {price}
          {/* <span className="line-through ml-2">{realPrice}</span> *
        </div> */}
      </div>
    </>
  );
};

export default HomeCard;
