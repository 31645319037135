// import React from "react";
// import { CaretRightOutlined } from "@ant-design/icons";
// import { Collapse, theme } from "antd";
// import { faqs } from "../utils/data";

// const getItems = (panelStyle) =>
//   faqs.map((faq, index) => ({
//     key: index + 1,
//     label: faq.question,
//     children: <p>{faq.answer}</p>,
//     style: panelStyle,
//   }));

// const FAQ = () => {
//   const { token } = theme.useToken();
//   const panelStyle = {
//     marginBottom: 24,
//     background: token.colorFillAlter,
//     borderRadius: token.borderRadiusLG,
//     border: "none",
//   };

//   return (
//     <div className="container mx-auto p-4 font-inter text-[#071524]">
//       <h1 className="text-2xl font-bold mb-4 font-baskerville">FAQ</h1>
//       <Collapse
//         bordered={false}
//         defaultActiveKey={["1"]}
//         expandIcon={({ isActive }) => (
//           <CaretRightOutlined rotate={isActive ? 90 : 0} />
//         )}
//         style={{
//           background: token.colorBgContainer,
//         }}
//         items={getItems(panelStyle)}
//       />
//     </div>
//   );
// };

// export default FAQ;

import React, { useState } from "react";
import { faqs } from "../utils/data";
import { FaAngleDown } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-[90%] md:max-w-[80%] w-full  mx-auto flex pt-4 flex-col">
      <h1 className="text-2xl font-bold text-[#071524] mb-6 font-baskerville">
        FAQ Page
      </h1>
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4">
          <button
            onClick={() => handleToggle(index)}
            className="w-full text-left bg-[#ffe4c4] p-4 rounded-lg shadow-md flex justify-between items-center"
          >
            <span className="text-lg font-medium text-[#071524]">{`${
              index + 1
            }. ${faq.question}`}</span>
            <span
              className={`transition-transform duration-300 ${
                openIndex === index ? "rotate-180" : ""
              }`}
            >
              <GoChevronDown color="#071524" size={20} />
            </span>
          </button>
          {openIndex === index && (
            <div className="mt-2 p-4 bg-gray-100 border border-gray-300 rounded-lg">
              <p className="text-[#071524] whitespace-pre-line">{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
