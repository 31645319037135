import React, { useEffect, useState } from "react";
import PageFrame from "../components/PageFrame";
import { Modal, Table } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const RSVP = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState({});
  const dataSource = [
    {
      key: "1",
      name: "Krishna",
      age: "All",
      address: "Yes",
    },
    {
      key: "2",
      name: "Mohan",
      age: "All Expect Bedai",
      address: "Yes",
    },
    {
      key: "3",
      name: "Amit",
      age: "All Expect Ganesh Pujan",
      address: "Yes",
    },
    {
      key: "4",
      name: "Narendra",
      age: "All Expect Bedai",
      address: "Yes",
    },
    {
      key: "5",
      name: "Raju",
      age: "All Expect Barat",
      address: "Yes",
    },
  ];

  const columns = [
    {
      title: "Guest Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Events",
      dataIndex: "age",
      key: "age",
      responsive: ["md"],
    },
    {
      title: "Availability",
      dataIndex: "address",
      key: "address",
      responsive: ["md"],
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Show a confirmation dialog
      event.preventDefault();
      event.returnValue = ""; // Required for the confirmation dialog to show in some browsers
      setIsModalVisible(true);
      return ""; // Some browsers require this to display the dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleConfirm = () => {
    setIsModalVisible(false);
    window.location.href = "/"; // Redirect to home page
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [base64, setBase64] = useState("");
  const user = JSON.parse(localStorage.getItem("token"));

  const onSubmit = () => {
    let data = base64;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + "post-user-file",
      headers: {
        Auth_ID_Token: user.IdToken,
        Access_Token: user.AccessToken,
        File_Name: "test.txt",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64(reader.result); // Result is a Base64 string
      };

      reader.readAsDataURL(file); // Read the file as a Base64-encoded string
    }
  };
  return (
    <PageFrame current_page={"RSVP"}>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        className="m-5"
        pagination={false}
      />
      <Modal
        title="Page Refreshed"
        open={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Go to Home"
        cancelText="Stay on Page"
      >
        <p>
          The page was refreshed. Do yoergegergregregeu want to go to the home
          page?
        </p>
      </Modal>

      <input
        type="file"
        // value={file}
        onChange={handleFileChange}
      />
      <button onClick={onSubmit}>submit</button>
    </PageFrame>
  );
};

export default RSVP;
