import React, { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import TemplateDetailPage from "./pages/template/TemplateDetailPage";
import TemplateDetailEdit from "./pages/template/TemplateDetailEdit";
import Login from "./pages/auth/Login";
import Profile from "./pages/Profile";
import MyFolders from "./pages/MyFolders";
import RSVP from "./pages/RSVP";
import CoverTemplateEdit from "./pages/template/templateEdit/CoverTemplateEdit";
import FamilyDetailTemplateEdit from "./pages/template/templateEdit/FamilyDetailTemplateEdit";
import EventTemplateEdit from "./pages/template/templateEdit/EventTemplateEdit";
import MusicTemplateEdit from "./pages/template/templateEdit/MusicTemplateEdit";
import DownloadTemplateEdit from "./pages/template/templateEdit/DownloadTemplateEdit";
import { notification } from "antd";
import NotFound from "./pages/NotFound";
import { store } from "./redux/store";
import { fetchCategories, setMy_Theme } from "./redux/slice/userSlice";
import TermsAndConditions from "./pages/TermsAndConditions";
import FAQ from "./pages/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import PricingCard from "./pages/PricingCard";

let openNotificationWithIcon;
const App = () => {
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === "PrintScreen") {
  //       alert("Screenshots are disabled.");
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (
  //       (event.ctrlKey && event.key === "p") || // Ctrl+P
  //       (event.ctrlKey && event.key === "s") || // Ctrl+S
  //       (event.ctrlKey && event.key === "Shift" && event.key === "i") || // Ctrl+Shift+I
  //       event.key === "F12" // F12
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  const [api, contextHolder] = notification.useNotification();
  openNotificationWithIcon = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  useEffect(() => {
    store.dispatch(fetchCategories());
    store.dispatch(setMy_Theme({}));
  }, []);

  return (
    <div className="no-select overflow-hidden">
      {contextHolder}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="template/:slug" element={<TemplateDetailPage />} />
          <Route path="template/:slug/edit/" element={<TemplateDetailEdit />}>
            <Route path="cover-page" element={<CoverTemplateEdit />} />
            <Route
              path="family-details"
              element={<FamilyDetailTemplateEdit />}
            />
            <Route path="events" element={<EventTemplateEdit />} />
            <Route path="add-music" element={<MusicTemplateEdit />} />
            <Route path="download" element={<DownloadTemplateEdit />} />
          </Route>

          <Route path="plans" element={<PricingCard />} />
          <Route path="profile" element={<Profile />} />
          <Route path="my-order" element={<MyFolders />} />
          <Route path="terms-condition" element={<TermsAndConditions />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="rsvp" element={<RSVP />} />
        </Route>
      </Routes>
      <Login />
    </div>
  );
};

export { openNotificationWithIcon };
export default App;
