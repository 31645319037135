import axios from "axios";
import { store } from "../redux/store";
import { setOrder_history, setVideo_Data } from "../redux/slice/userSlice";
import { isTokenExpired } from "./utils";
import { openNotificationWithIcon } from "../App";
import { useContext } from "react";
import myContext from "../state/myContext";

export const get_theme_detail = async (cat, s_cat, id) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        `get-theme?category=${cat}&sub-category=${s_cat}&theme=${id}`,
      { maxBodyLength: Infinity }
    );

    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const get_order_history = async () => {
  if (isTokenExpired()) {
    openNotificationWithIcon(
      "error",
      "Alert",
      "Your token has expired, Please login"
    );
    // localStorage.removeItem("token");
    // setShow_login_modal(true);
    return;
  }
  const user = JSON.parse(localStorage.getItem("token"));
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + "get-orders",
    headers: {
      Auth_ID_Token: user.IdToken,
      Access_Token: user.AccessToken,
    },
  };
  try {
    const response = await axios.request(config);
    console.log("get==", response.data);
    if (
      response.data.statusCode == "200" &&
      response.data.message == "Success"
    ) {
      // get_order(response.data.order_id);
      // store.dispatch(setPost_order(response.data));
    } else {
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
export const get_orders = async () => {
  if (isTokenExpired()) {
    openNotificationWithIcon(
      "error",
      "Alert",
      "Your token has expired, Please login"
    );
    return;
  }
  const user = JSON.parse(localStorage.getItem("token"));

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `get-orders`,
    headers: {
      Auth_ID_Token: user.IdToken,
      Access_Token: user.AccessToken,
    },
  };

  try {
    const response = await axios.request(config);
    console.log("post==", response);
    if (response.data.statusCode == "200") {
      store.dispatch(setOrder_history(response.data.body));
    } else {
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
export const get_single_order = async (order_id) => {
  const user = JSON.parse(localStorage.getItem("token"));
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `get-orders?order_id=${order_id}`,
    headers: {
      Auth_ID_Token: user.IdToken,
      access_token: user.AccessToken,
    },
  };

  try {
    const response = await axios.request(config);
    console.log("single==", response?.data);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const get_video = async (order_id, setShow_login_modal) => {
  if (isTokenExpired()) {
    openNotificationWithIcon(
      "error",
      "Alert",
      "Your token has expired, Please login"
    );
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    setShow_login_modal(true);
    return;
  }
  const user = JSON.parse(localStorage.getItem("token"));
  console.log("order_id", order_id, user);
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_API_URL + `get-order-video?order_id=${order_id}`,
    headers: {
      Auth_ID_Token: user.IdToken,
      Access_Token: user.AccessToken,
    },
  };

  try {
    const response = await axios.request(config);
    console.log("post==", response);
    if (response.data.statusCode == "200") {
      store.dispatch(setVideo_Data(response.data.body));
    } else {
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
