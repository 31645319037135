import React, { useEffect, useState } from "react";
import MyContext from "./myContext";
import axios from "axios";
import { store } from "../redux/store";
import { setSave_the_date } from "../redux/slice/userSlice";

function MyState(props) {
  const base_url =
    "https://yzsip1a772.execute-api.ap-south-1.amazonaws.com/dev/";

  const [show_banner, setShow_banner] = useState(true);
  const [selected_theme, setSelected_theme] = useState({});
  const [selected_cat, setSelected_cat] = useState("");
  const [activeTab, setActiveTab] = useState("Wedding");
  const [show_login_modal, setShow_login_modal] = useState(false);
  const [cover_data, setCover_data] = useState({});
  const [sections, setSections] = useState([]);
  const [imageBoxes, setImageBoxes] = useState([]);
  const [json_data, setJson_data] = useState([]);

  const [banner_list, setBanner_list] = useState({
    loading: false,
    list: [],
  });
  const [home_theme, setHome_Theme] = useState({
    loading: false,
    list: [],
  });
  const [category_data, setCategory_data] = useState({
    loading: false,
    list: [],
  });

  const get_banner = async () => {
    try {
      setBanner_list({ ...banner_list, loading: true });
      const response = await axios.get(base_url + "get-banners", {});
      setBanner_list({ ...banner_list, loading: false });
      if (response.status == 200) {
        setBanner_list({ ...banner_list, list: response.data?.body });
      } else {
        setBanner_list({ ...banner_list, list: [] });
      }
    } catch (error) {
      setBanner_list({ ...banner_list, loading: false });
      console.error("Error:", error);
    }
  };

  const get_categories = async () => {
    try {
      setCategory_data({ ...banner_list, loading: true });
      const response = await axios.get(base_url + "list-categories", {
        maxBodyLength: Infinity,
      });
      setCategory_data({ ...banner_list, loading: false });
      if (response.status == 200) {
        store.dispatch(setSave_the_date(false));
        setCategory_data({ ...banner_list, list: response.data?.body });
        setSelected_cat(response.data?.body?.Wedding[0]);
        // console.log(
        //   "response.data?.body?",
        //   Object.keys(response.data?.body)[0]
        // );
        get_home_theme_list("Wedding", response.data?.body?.Wedding[0], false);
      } else {
        setCategory_data({ ...banner_list, list: [] });
      }
    } catch (error) {
      setCategory_data({ ...banner_list, loading: false });
      console.error("Error:", error);
    }
  };
  const get_home_theme_list = async (cat, s_cat, save = false) => {
    try {
      setHome_Theme({ ...banner_list, loading: true });
      const response = await axios.get(
        save
          ? base_url +
              `list-themes?category=${cat}&sub-category=${s_cat}&save-the-date=Y`
          : base_url + `list-themes?category=${cat}&sub-category=${s_cat}`,
        { maxBodyLength: Infinity }
      );

      setHome_Theme({ ...banner_list, loading: false });
      if (response.status == 200) {
        setHome_Theme({ ...banner_list, list: response?.data?.body });
      } else {
        setHome_Theme({ ...banner_list, list: [] });
      }
    } catch (error) {
      setHome_Theme({ ...banner_list, loading: false });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    get_banner();
    get_categories();
  }, []);

  // Save user data to localStorage whenever it changes
  useEffect(() => {
    json_data?.length > 0 &&
      localStorage.setItem("THEME", JSON.stringify(json_data));
  }, [json_data]);

  useEffect(() => {
    const storedUser = localStorage.getItem("THEME");
    if (storedUser) {
      setJson_data(JSON.parse(storedUser));
    }
  }, []);
  return (
    <MyContext.Provider
      value={{
        banner_list,
        get_banner,
        get_home_theme_list,
        home_theme,
        get_categories,
        category_data,
        selected_cat,
        setSelected_cat,
        selected_theme,
        setSelected_theme,
        show_banner,
        setShow_banner,
        activeTab,
        setActiveTab,
        show_login_modal,
        setShow_login_modal,
        cover_data,
        setCover_data,
        sections,
        setSections,
        imageBoxes,
        setImageBoxes,
        json_data,
        setJson_data,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
