import icon_users from "../assets/icons/icon_users.svg";
import step1 from "../assets/svgimg/step11.svg";
import step2 from "../assets/svgimg/step12.svg";
import step3 from "../assets/svgimg/step13.svg";
import step4 from "../assets/svgimg/step14.svg";
import step5 from "../assets/svgimg/step15.svg";
import step6 from "../assets/svgimg/step16.svg";
import icon_tv from "../assets/icons/icon_tv.svg";
import icon_dimond from "../assets/icons/icon_dimond.svg";
import icon_doller_circle from "../assets/icons/icon_doller_circle.svg";
import icon_heart from "../assets/icons/icon_heart.svg";
import icon_download from "../assets/icons/icon_download.svg";

export const categoryData = [
  "All",
  "Hindu Wedding",
  "Sindhi Wedding",
  "Jain Wedding",
  "Cristian Wedding",
  "Islamic Wedding",
  "Panjabi Wedding",
];

export const cardData = [
  "Gold blue royal template",
  "Gold blue royal template",
  "Gold blue royal template",
  "Gold blue royal template",
  "Gold blue royal template",
  "Gold blue royal template",
  "Gold blue royal template",
  "Gold blue royal template",
];

export const features = [
  {
    icon: step1,
    title: "Buy Plan",
    description:
      "Firstly you have to buy a plan we have three type of plan Free Premium and Pro you can select plan according to your needs ",
  },
  {
    icon: step2,
    title: "Edit Templates",
    description: `Once you've found the template that fits your event, click on the "Edit" button to start personalizing it.`,
  },
  {
    icon: step3,
    title: "Fill Details",
    description:
      "Fill in all the necessary details for your invitation. Include names, dates, venues, and any other important information.",
  },
  {
    icon: step5,
    title: "Review Details",
    description:
      "Review all the details to ensure everything is correct. Once confirmed, our system will generate a high-quality video and card for you.",
  },
  {
    icon: step4,
    title: "Add Music",
    description:
      "Make your invitation extra special by adding your favorite music. Choose from our library or upload your own track to set the perfect tone.",
  },
  {
    icon: step6,
    title: "Download video & PDF",
    description:
      "After your invitation is ready, simply download it in your preferred format. Share it with your guests via social media, email, or messaging apps",
  },
];
// export const features = [
//   {
//     icon: step1,
//     title: "Browse Templates",
//     description:
//       "Browse through our extensive collection of beautifully designed templates. Whether it's a wedding, festival, or special occasion, we have something for everyone.",
//   },
//   {
//     icon: step2,
//     title: "Edit Templates",
//     description: `Once you've found the template that fits your event, click on the "Edit" button to start personalizing it.`,
//   },
//   {
//     icon: step3,
//     title: "Fill Details",
//     description:
//       "Fill in all the necessary details for your invitation. Include names, dates, venues, and any other important information.",
//   },
//   {
//     icon: step4,
//     title: "Add Music",
//     description:
//       "Make your invitation extra special by adding your favorite music. Choose from our library or upload your own track to set the perfect tone.",
//   },
//   {
//     icon: step5,
//     title: "Review Details",
//     description:
//       "Review all the details to ensure everything is correct. Once confirmed, our system will generate a high-quality video and card for you.",
//   },
//   {
//     icon: step6,
//     title: "Download video & PDF",
//     description:
//       "After your invitation is ready, simply download it in your preferred format. Share it with your guests via social media, email, or messaging apps",
//   },
// ];

export const fields = [
  "Family Members Name line 1",
  "Family Members Name line 2",
  "Family Members Name line 3",
  "Family Members Name line 4",
  "Your message line 5",
  "Your message line 1",
  "Address line - 1",
  "Address line - 2",
  "Address line - 1",
  "Address line - 2",
];

export const festival_more = [
  "Naming Ceremony or Namkaran Sanskar",
  "Hindu Festiavel",
  "Gudi Padwa/Ugadi",
  "Rama Navami",
  "Raksha Bandhan",
  "Krishna Janmashtami",
  "Ganesh Chaturthi",
  "Durga Ashtami",
  "Karva Chauth",
  "Dhan Teras",
  "Maha Shivaratri",
  "Sri Rama Navami",
  "Hanuman Jayanti",
  "Diwali ",
  "Vidyarambh Sansakaar",
];

export const Valentine_more = [
  "Rose Day",
  "Propose Day",
  "Chocolate Day",
  "Teddy Day",
  "Promise Day",
  "Hug Day",
  "Kiss Day",
  "Valentine's Day",
];

export const wedding_more = [
  "Mata Pujan",
  "Ganesh Pujan",
  "Reception ",
  "Haldi",
  "Sagai",
  "Mahila Sangeet",
  "All Event",
];

export const jsonData = {
  pageName: "Cover",
  pageVideo:
    "https://s3-invitecard-me.s3.amazonaws.com/themes/Wedding/Hindu%20Wedding/ich-1-E/cover.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165118Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=95223ec9481f78dc610c9ac4ba6e2d91eb2a57185aea55a2f0fdfaec2d134ed0",
  pageId: "ich-1-E-01",
  sections: [
    {
      sectionName: "bride_groom_image",
      sectionType: "image",
      placementX: 200,
      placementY: 300,
      imageSrc:
        "https://s3-invitecard-me.s3.amazonaws.com/themes/Wedding/Hindu%20Wedding/ich-1-E/groom.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165118Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=c718063f9992438fc9367c5f6bf8da1548ac83310a99e40e57b6033e16a2d869",
      height: 672,
      width: 448,
      radius: 20,
    },
    {
      sectionName: "bride_groom_image",
      sectionType: "image",
      placementX: 200,
      placementY: 420,
      imageSrc:
        "https://s3-invitecard-me.s3.amazonaws.com/themes/Wedding/Hindu%20Wedding/ich-1-E/bride.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165119Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=fd29b7b219e0211db7f725f8220ae804482542d8b31db0c2da7328d69c1703d0",
      height: 672,
      width: 448,
      radius: 20,
    },
    {
      sectionName: "groom_name",
      sectionType: "text",
      fontSize: 90,
      fontName: "RobotoSlab",
      placementX: "(w-text_w)/2",
      placementY: 950,
      text: "Your Groom Name",
      fontColor: "#6D483A",
      fontFile:
        "https://s3-invitecard-me.s3.amazonaws.com/fonts/RobotoSlab.ttf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165119Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=4c689986607a37d1848861bf0c4f865e79241cce742a65507fe3003e6189e6b8",
      lineSpacing: 125,
      maxWidth: 800,
    },
    {
      sectionName: "weds",
      sectionType: "text",
      fontSize: 100,
      fontName: "GreatVibesRegular",
      placementX: "(w-text_w)/2",
      placementY: 1080,
      text: "&",
      fontColor: "#6D483A",
      fontFile:
        "https://s3-invitecard-me.s3.amazonaws.com/fonts/GreatVibesRegular.ttf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165119Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=c52114e031ec7f572c37655587c20bf58e6b0c8c2a6625ddeb59a954ef85823f",
      lineSpacing: 125,
      maxWidth: 500,
    },
    {
      sectionName: "bridal_name",
      sectionType: "text",
      fontSize: 100,
      fontName: "RobotoSlab",
      placementX: "(w-text_w)/2",
      placementY: 1220,
      text: "Your Bridal Name",
      fontColor: "#6D483A",
      fontFile:
        "https://s3-invitecard-me.s3.amazonaws.com/fonts/RobotoSlab.ttf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165119Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=4c689986607a37d1848861bf0c4f865e79241cce742a65507fe3003e6189e6b8",
      lineSpacing: 125,
      maxWidth: 800,
    },
    {
      sectionName: "date",
      sectionType: "text",
      fontSize: 80,
      fontName: "RobotoSlab",
      placementX: "(w-text_w)/2",
      placementY: 1555,
      text: "Save the date",
      fontColor: "red",
      fontFile:
        "https://s3-invitecard-me.s3.amazonaws.com/fonts/RobotoSlab.ttf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165119Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=4c689986607a37d1848861bf0c4f865e79241cce742a65507fe3003e6189e6b8",
      lineSpacing: 73,
      maxWidth: 400,
    },
    {
      sectionName: "date",
      sectionType: "text",
      fontSize: 55,
      fontName: "RobotoSlab",
      placementX: "(w-text_w)/2",
      placementY: 1740,
      text: "MM DD YY",
      fontColor: "White",
      fontFile:
        "https://s3-invitecard-me.s3.amazonaws.com/fonts/RobotoSlab.ttf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQ74BCV3MQ%2F20240601%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240601T165119Z&X-Amz-Expires=1800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAqUG6waSUQ4zYr0NJx30%2BzGpUbGnmL%2BOtD%2FRrAU8HYagCIQC8EqRm4jx%2FVfL5XAzVCx0nAJmAEKg5CCekejigXaGqnCrvAghiEAIaDDMyNjMxNTk5NjY0MSIM4ZSMqqFmJWvgKFYwKswC50yTEXfc0%2BnHeZUiPbutsqojxfcbOuE%2F4SJbg3vkP0SmutAydu%2FvXfNiBHFqf5IaTzSXIH%2FFD8%2BElCJdKy3%2F1hqZ3muFAezGtCii6gw2%2FPqCmHSwNF%2BS%2Bc3eScaiNE229aKIiTcWvPHfCOBV1um6gLkvvY0HG7%2FtXuxsp4DbA8KV4PtxJ%2BgbgHD98Eq0BM40%2BkDPnxDrCcMQS1D8T%2BcOYRkCC3HfcN0wWGIVdAAVf%2BAoddbmTpsFVd883KO%2FHqTCUu1KNeDRP6ZODC5kSk6nim8isTZFJfd1gGpK8uBxWqPdK65vupUI32pG4GfZ4oZB%2BGyZzrXnwD%2B8BccxixNmjnnRY0sq1M9dSuKoZLg0MUkqi1ij2YBex%2BBzk4tIWX8UGYqQc0sHuPadnv8KlfujTjhrnTB8RyZNdswNvEih7uZPmxum4GeeSOw0lewwg6PtsgY6nQHlLtDoXgyjsqy9jeUWfCighvC%2FMpw3qbQwy6DOQiKMS4rlWoV4OyDfLj49heMN0K69H4cRMSlC7%2FThanwak7%2F5kd7MBGdpvaOoS40WuV0CjOlgCHIrs41DR1vUMdxOpYAM6jRn07BzoAkeE6mYcWAmfWEvySTRbiERpC5TajTpGUKXXJRE7FoCURAONY7vwyfARETCIPH7kpijz8V9&X-Amz-Signature=4c689986607a37d1848861bf0c4f865e79241cce742a65507fe3003e6189e6b8",
      lineSpacing: 73,
      maxWidth: 400,
    },
  ],
};

export const banner_list1 = [
  {
    name: "banner-1",
    signed_url:
      "https://s3-invitecard-me.s3.amazonaws.com/banner/banner-1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQZWGSAQXM%2F20240518%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240518T074616Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEID%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAwj4WHoakEtJbr5rUHowM%2BnrTcwy9jJiqKZe6fWMBQBACIQDlxGPGp88p6Tz6jZByVkDFIP%2BAxBMHxBTND0GLi3aUrCr8Agjp%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDMyNjMxNTk5NjY0MSIM3OszVht4%2Blexr0A4KtAC%2FQlYEVxKwZbzSNanP4NAIOJjwdwwNXWFJyE6ZHoGpfAkNeeas33LGT9t3KhV97fkFCXbuzjdhVdyz8n8C7xbgQTPZ2219AOKttvG6B8ihAuQWnbLpneacgWH4OKpbUV2VfQMnuvC%2BZB1bcBPphfV9%2FsgiOkP7eSsCIjf17Oo2k3GJqjLMg7pPur8Cf4S9hJp3W%2BDHj6mybjQxb6TYyvly7aNFURk%2BASYO5TLGaE3SU0sDayyJUv1QkzbhByQL1GXcu9JLTmo3n9nepRMp4YIOLs29BW8E6HoQOCPNIjymF5Dikl4c6m0C6WZigfO%2BAftOZ%2FB3Oyv%2BYwEOsDCs%2FJMEpM2w21XGqDJAsSa3Yx21oNb42SkuiUwlGvIO6WneYetYVeghHyxhLfM3NjSzi9Nu5g8m1aDtK6Puu0SQ0EmmWsfCSIyhPNuP8TpodL2V2bnMMS5obIGOp0BV2fklyszAYNBHq%2FgP%2FvkT5IVMV8yoca22geDbWZhaYp3mr3v8z%2Ba5zg4O%2Bm%2BwdyjzhKsIc9fkyxRd6uyF8cKqZaRjkDRHUWjZvvyrFiWt0eACZVyG2q4i%2F00p4bULsyKnfGqPq5iyqar2DQ6ChYcynboU6beIyfbPwU17wjcMX92z1F5C%2B6o7O%2FD5IKdXWk1JiP%2F5huCmO5cGDkniQ%3D%3D&X-Amz-Signature=af2dc8b7ffd2aee1de45e667802cca0cc5e3e9131a0fefe02032fd978496e4fb",
    link: "http://www.dev.invitecard.me",
  },
  {
    name: "banner-2",
    signed_url:
      "https://s3-invitecard-me.s3.amazonaws.com/banner/banner-2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQZWGSAQXM%2F20240518%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240518T074616Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEID%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAwj4WHoakEtJbr5rUHowM%2BnrTcwy9jJiqKZe6fWMBQBACIQDlxGPGp88p6Tz6jZByVkDFIP%2BAxBMHxBTND0GLi3aUrCr8Agjp%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDMyNjMxNTk5NjY0MSIM3OszVht4%2Blexr0A4KtAC%2FQlYEVxKwZbzSNanP4NAIOJjwdwwNXWFJyE6ZHoGpfAkNeeas33LGT9t3KhV97fkFCXbuzjdhVdyz8n8C7xbgQTPZ2219AOKttvG6B8ihAuQWnbLpneacgWH4OKpbUV2VfQMnuvC%2BZB1bcBPphfV9%2FsgiOkP7eSsCIjf17Oo2k3GJqjLMg7pPur8Cf4S9hJp3W%2BDHj6mybjQxb6TYyvly7aNFURk%2BASYO5TLGaE3SU0sDayyJUv1QkzbhByQL1GXcu9JLTmo3n9nepRMp4YIOLs29BW8E6HoQOCPNIjymF5Dikl4c6m0C6WZigfO%2BAftOZ%2FB3Oyv%2BYwEOsDCs%2FJMEpM2w21XGqDJAsSa3Yx21oNb42SkuiUwlGvIO6WneYetYVeghHyxhLfM3NjSzi9Nu5g8m1aDtK6Puu0SQ0EmmWsfCSIyhPNuP8TpodL2V2bnMMS5obIGOp0BV2fklyszAYNBHq%2FgP%2FvkT5IVMV8yoca22geDbWZhaYp3mr3v8z%2Ba5zg4O%2Bm%2BwdyjzhKsIc9fkyxRd6uyF8cKqZaRjkDRHUWjZvvyrFiWt0eACZVyG2q4i%2F00p4bULsyKnfGqPq5iyqar2DQ6ChYcynboU6beIyfbPwU17wjcMX92z1F5C%2B6o7O%2FD5IKdXWk1JiP%2F5huCmO5cGDkniQ%3D%3D&X-Amz-Signature=1f9227e2969615c02207887d77ee95e7139405fd4a42016b208fb239cc679c72",
    link: "http://www.dev.invitecard.me",
  },
  {
    name: "banner-3",
    signed_url:
      "https://s3-invitecard-me.s3.amazonaws.com/banner/banner-3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAUX6PS6XQZWGSAQXM%2F20240518%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240518T074616Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEID%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiSDBGAiEAwj4WHoakEtJbr5rUHowM%2BnrTcwy9jJiqKZe6fWMBQBACIQDlxGPGp88p6Tz6jZByVkDFIP%2BAxBMHxBTND0GLi3aUrCr8Agjp%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDMyNjMxNTk5NjY0MSIM3OszVht4%2Blexr0A4KtAC%2FQlYEVxKwZbzSNanP4NAIOJjwdwwNXWFJyE6ZHoGpfAkNeeas33LGT9t3KhV97fkFCXbuzjdhVdyz8n8C7xbgQTPZ2219AOKttvG6B8ihAuQWnbLpneacgWH4OKpbUV2VfQMnuvC%2BZB1bcBPphfV9%2FsgiOkP7eSsCIjf17Oo2k3GJqjLMg7pPur8Cf4S9hJp3W%2BDHj6mybjQxb6TYyvly7aNFURk%2BASYO5TLGaE3SU0sDayyJUv1QkzbhByQL1GXcu9JLTmo3n9nepRMp4YIOLs29BW8E6HoQOCPNIjymF5Dikl4c6m0C6WZigfO%2BAftOZ%2FB3Oyv%2BYwEOsDCs%2FJMEpM2w21XGqDJAsSa3Yx21oNb42SkuiUwlGvIO6WneYetYVeghHyxhLfM3NjSzi9Nu5g8m1aDtK6Puu0SQ0EmmWsfCSIyhPNuP8TpodL2V2bnMMS5obIGOp0BV2fklyszAYNBHq%2FgP%2FvkT5IVMV8yoca22geDbWZhaYp3mr3v8z%2Ba5zg4O%2Bm%2BwdyjzhKsIc9fkyxRd6uyF8cKqZaRjkDRHUWjZvvyrFiWt0eACZVyG2q4i%2F00p4bULsyKnfGqPq5iyqar2DQ6ChYcynboU6beIyfbPwU17wjcMX92z1F5C%2B6o7O%2FD5IKdXWk1JiP%2F5huCmO5cGDkniQ%3D%3D&X-Amz-Signature=5c5ae3eca5ca073c969be3cce4f1d0c5e2695a9baa3ff278a30096350a1f52b5",
    link: "http://www.dev.invitecard.me",
  },
];

export const faqs = [
  {
    question:
      "Can I edit the text color and placement on the invitation templates?",
    answer:
      "No, to maintain the design integrity and avoid confusion, users can only add text details. The text color and placement are fixed to ensure the invitations look professional and visually appealing.",
  },
  {
    question: "How do I create an invitation?",
    answer: (
      <div className="block">
        <p className="font-normal mb-2">
          Creating an invitation is easy! Follow these steps:
        </p>
        <ol className="list-decimal pl-5 space-y-1">
          <li>Select a template from our extensive collection.</li>
          <li>Click on the "Edit" button to start personalizing.</li>
          <li>
            Enter the necessary details such as names, dates, times, and
            locations.
          </li>
          <li>Add your favorite music to the video invitation.</li>
          <li>Confirm the details and generate your video and card PDF.</li>
        </ol>
      </div>
    ),
  },
  {
    question:
      "Do I need to enter details separately for the video and card PDF?",
    answer:
      "No, you only need to enter the details once. Our system will automatically use the same information to create both the video invitation and the card PDF in a single attempt.",
  },
  {
    question: "Can I use my own music for the video invitation?",
    answer:
      "Yes, you can either choose from our library of music or upload your own track to personalize your video invitation.",
  },
  {
    question: "How do I download my invitations?",
    answer:
      "After generating your video and card PDF, you can download them directly from our website. There will be download links provided for both formats.",
  },
  {
    question: "Can I share my invitations directly from your platform?",
    answer:
      "Yes, once you’ve downloaded your invitations, you can easily share them via social media, email, or messaging apps.",
  },
  {
    question: "Are there any costs associated with creating invitations?",
    answer:
      "We offer a range of pricing options, including free and premium templates. Special features and high-quality designs may come with a fee. Please visit our pricing page for more details.",
  },
  {
    question: "What types of invitations can I create on your platform?",
    answer: (
      <div className="">
        <p className="font-normal mb-2">
          Our platform supports a wide variety of invitations including:
        </p>
        <ul className="list-disc pl-5 space-y-1">
          <li>
            Birthday Invitations (Grow-up, Kids Boy, Kids Girl, Teenager,
            Youngster)
          </li>
          <li>
            Valentine's Week Invitations (Rose Day, Propose Day, Chocolate Day,
            Teddy Day, Promise Day, Hug Day, Kiss Day, Valentine's Day)
          </li>
          <li>
            Engagement Invitations (Bengali, Christian, Gujarati, Hindu, Jain,
            Marathi, Punjabi, Rajasthani, Sindhi)
          </li>
          <li>All Hindu Festival Invitations</li>
        </ul>
      </div>
    ),
  },
  {
    question: "How do I contact customer support if I need help?",
    answer: (
      <div className="">
        <p className="font-normal mb-2">
          If you need any assistance, you can reach our customer support team
          via:
        </p>
        <ul className="list-disc pl-5 space-y-1">
          <li>Email: support@[yourwebsite].com</li>
          <li>Phone: +1 (123) 456-7890</li>
          <li>Live Chat: Available 24/7 on our website</li>
        </ul>
      </div>
    ),
  },
  {
    question: "What file formats are available for download?",
    answer:
      "You can download your invitations in high-quality video format (HD) and PDF format for the card.",
  },
  {
    question: "Can I preview my invitation before downloading?",
    answer:
      "Yes, you can preview your invitation to make sure everything looks perfect before you proceed to download it.",
  },
  {
    question: "Is my information secure on your platform?",
    answer:
      "Yes, we prioritize your privacy and security. All personal information is encrypted and securely stored. Please refer to our Privacy Policy for more details.",
  },
  {
    question:
      "Do you offer templates for specific cultural or religious events?",
    answer: `Yes, we offer a wide range of templates catering to various cultural and religious events, including Bengali, Christian, Gujarati, Hindu, Jain, Marathi, Punjabi, Rajasthani, and Sindhi engagements, as well as all major Hindu festivals.`,
  },
  {
    question: "What if I need to make changes after generating the invitation?",
    answer:
      "If you need to make any changes after generating your invitation, you can go back, edit the details, and regenerate the invitation. Please ensure all details are correct before finalizing.",
  },
  {
    question: "How can I stay updated with new templates and features?",
    answer:
      "You can stay updated by subscribing to our newsletter or following us on social media. We regularly update our collection with new templates and features.",
  },
];

// const signIn = async () => {
//   if (!form.email || !form.password) {
//     messageApi.open({
//       type: "warning",
//       content: "Please enter username or password.",
//       duration: 2,
//     });
//     return;
//   }

//   setSubmitting(true);
//   const AWS = require("aws-sdk");
//   const CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider;
//   const secretHash = await generateSecretHash(form.email);
//   const cognito = new CognitoIdentityServiceProvider();

//   const params = {
//     AuthFlow: "USER_PASSWORD_AUTH",
//     ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
//     AuthParameters: {
//       USERNAME: form.email,
//       PASSWORD: form.password,
//       SECRET_HASH: secretHash,
//     },
//   };
//   setSubmitting(false);
//   try {
//     const result = await cognito.initiateAuth(params).promise();
//     messageApi.open({
//       type: "success",
//       content: "Authentication successful",
//       duration: 2,
//     });
//     console.log(result);
//     // window.location.reload();
//     localStorage.setItem(
//       "token",
//       JSON.stringify(result.AuthenticationResult)
//     );
//     console.log(result.AuthenticationResult.ExpiresIn);
//     const expirationTime =
//       Date.now() + result.AuthenticationResult.ExpiresIn * 1000; // Current time + expiresIn in milliseconds
//     localStorage.setItem("tokenExpiration", expirationTime);
//     setShow_login_modal(false);
//     return {
//       status: "success",
//       message: "Authentication successful",
//       data: result,
//     };
//   } catch (error) {
//     if (error instanceof Error) {
//       console.log("Error name:", error.name);
//       // Handle different types of errors based on their names
//       if (error.name === "NotAuthorizedException") {
//         // Handle UsernameExistsException
//         messageApi.open({
//           type: "error",
//           content: "Incorrect username or password.",
//           duration: 2,
//         });
//       } else if (error.name === "UserNotConfirmedException") {
//         // Handle AnotherErrorType
//         messageApi.open({
//           type: "error",
//           content: "User is not confirmed.",
//           duration: 2,
//         });
//       } else {
//         messageApi.open({
//           type: "error",
//           content: "Authentication failed. Invalid username or password.",
//           duration: 2,
//         });
//       }

//       // Handle other errors, e.g., display error message to user
//     }
//   }
// };

// const handleVerification = async () => {
//   try {
//     const secretHash = await generateSecretHash(form.email); // Generate the secret hash

//     await cognitoIdentityServiceProvider
//       .confirmSignUp({
//         ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
//         Username: form.email,
//         ConfirmationCode: form.otp,
//         SecretHash: secretHash, // Include the secret hash
//       })
//       .promise();
//     console.log("OTP verification successful");
//     messageApi.open({
//       type: "success",
//       content: "OTP verification successful. Please sign in.",
//       duration: 3,
//     });
//     setOtp_modal(false);
//     setIsSignUp(false);
//     // Redirect user to authenticated area
//   } catch (error) {
//     console.error("Error verifying OTP", error);
//     if (error instanceof Error) {
//       console.log("Error name:", error.name);
//       // Handle different types of errors based on their names
//       if (error.name === "InvalidParameterException") {
//         // Handle UsernameExistsException
//         messageApi.open({
//           type: "error",
//           content: "Invalid Otp",
//           duration: 2,
//         });
//       } else if (error.name === "UserNotConfirmedException") {
//         // Handle AnotherErrorType
//         messageApi.open({
//           type: "error",
//           content: "User is not confirmed.",
//           duration: 2,
//         });
//       } else {
//         messageApi.open({
//           type: "error",
//           content: "Authentication failed. Invalid Otp.",
//           duration: 2,
//         });
//       }

//       // Handle other errors, e.g., display error message to user
//     }
//   }
// };
// const ResentOtp = async () => {
//   try {
//     const secretHash = await generateSecretHash(form.email); // Generate the secret hash

//     await cognitoIdentityServiceProvider
//       .resendConfirmationCode({
//         ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
//         Username: form.email,
//         SecretHash: secretHash, // Include the secret hash
//       })
//       .promise();
//     console.log("OTP verification code sent successful");
//     messageApi.open({
//       type: "success",
//       content: "OTP verification code sent successfully on your email",
//       duration: 3,
//     });
//     // Redirect user to authenticated area
//   } catch (error) {
//     console.error("Error verifying OTP", error);
//     if (error instanceof Error) {
//       console.log("Error name:", error.name);
//       // Handle different types of errors based on their names
//       if (error.name === "InvalidParameterException") {
//         // Handle UsernameExistsException
//         messageApi.open({
//           type: "error",
//           content: "Invalid Otp",
//           duration: 2,
//         });
//       } else if (error.name === "UserNotConfirmedException") {
//         // Handle AnotherErrorType
//         messageApi.open({
//           type: "error",
//           content: "User is not confirmed.",
//           duration: 2,
//         });
//       } else {
//         messageApi.open({
//           type: "error",
//           content: "Authentication failed. Invalid Otp.",
//           duration: 2,
//         });
//       }

//       // Handle other errors, e.g., display error message to user
//     }
//   }
// };

// const signUp = async () => {
//   if (
//     !form.email ||
//     !form.password ||
//     !form.cPassword ||
//     // !form.fName ||
//     !form.lName
//   ) {
//     messageApi.open({
//       type: "warning",
//       content: "Please enter required fields",
//       duration: 2,
//     });
//     return;
//   }
//   if (!emailregex.test(form.email)) {
//     messageApi.open({
//       type: "warning",
//       content: "Invailid email address",
//       duration: 2,
//     });
//     return;
//   }
//   if (!passregex.test(form.password)) {
//     messageApi.open({
//       type: "warning",
//       content:
//         "Password must contain a minimum of 8 characters with at least one number",
//       duration: 2,
//     });
//     return;
//   }
//   if (form.password !== form.cPassword) {
//     messageApi.open({
//       type: "warning",
//       content: "The password and confirmation password do not match.",
//       duration: 2,
//     });
//     return;
//   }
//   setSubmitting(true);
//   const cognito = new CognitoIdentityServiceProvider();
//   try {
//     const secretHash = await generateSecretHash(form.email); // Generate the secret hash
//     // Prepare user attributes array
//     const userAttributes = [
//       {
//         Name: "family_name",
//         Value: form.fName,
//       },
//       {
//         Name: "given_name",
//         Value: form.lName,
//       },
//       {
//         Name: "email",
//         Value: form.email,
//       },
//     ];

//     // Add middle name attribute if provided
//     if (form.mName) {
//       userAttributes.push({
//         Name: "middle_name",
//         Value: form.mName,
//       });
//     }

//     const response = await cognito
//       .signUp({
//         ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
//         SecretHash: secretHash,
//         Username: form.email,
//         Password: form.password,
//         UserAttributes: userAttributes,
//       })
//       .promise();
//     setSubmitting(false);
//     // Handle response based on status code
//     if (response.$response.httpResponse.statusCode === 200) {
//       console.log("User signed up successfully:", response);
//       setOtp_modal(true);
//       // Handle success, e.g., redirect to sign-in page
//       messageApi.open({
//         type: "success",
//         content: "User signed up successfully. Please sign in.",
//         duration: 2,
//       });
//       // setIsSignUp(false);
//     } else {
//       console.error("Error signing up user:", response);
//       // Extract error message from the preview field and display it in a popup
//       const errorMessage = response.preview || "Unknown error occurred.";
//       messageApi.open({
//         type: "error",
//         content: errorMessage,
//         duration: 2,
//       });
//     }
//   } catch (error) {
//     setSubmitting(false);
//     if (error instanceof Error) {
//       console.log("Error name:", error.name);
//       // Handle different types of errors based on their names
//       if (error.name === "UsernameExistsException") {
//         // Handle UsernameExistsException
//         messageApi.open({
//           type: "error",
//           content: "user already exists with this email address.",
//           duration: 2,
//         });
//       } else if (error.name === "InvalidParameterException") {
//         // Handle AnotherErrorType
//         messageApi.open({
//           type: "error",
//           content: error.message || "Please Fill Required fields properly.",
//           duration: 2,
//         });
//       } else {
//         // Handle other types of errors
//       }
//     }
//     // Handle other errors, e.g., display error message to user
//   }
// };
