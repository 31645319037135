import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const TemplateDetailEdit = () => {
  const { edit_page, save_the_date } = useSelector((state) => state.user);

  const list = save_the_date
    ? ["Cover Page", "Add Music"]
    : ["Cover Page", "Family Details", "Events", "Add Music"];

  return (
    <>
      <div className="relative flex justify-center flex-col ">
        <div className="flex max-w-[90%] xl:max-w-[80%] flex-col self-center w-full rounded-[10px] overflow-hidden border-[#07152433] border-[1px] mt-8">
          <div className="bg-[#103935]  flex w-full justify-center">
            <div className="flex overflow-y-auto">
              {list.map((v, i) => (
                <div
                  key={i}
                  className={`font-inter text-[12px] whitespace-nowrap w-[130px] text-center p-2 md:py-3 md:px-7 md:mt-1 border-b-[4px] ${
                    edit_page >= i
                      ? "border-b-[#FF9500] text-[#FFFFFF]"
                      : "border-b-transparent text-[#FFFFFF70]"
                  }`}
                >
                  {i + 1}
                  <br />
                  {v}
                </div>
              ))}
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default TemplateDetailEdit;
