import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-[90%] md:max-w-[80%] w-full  mx-auto flex">
      <div className="font-inter text-[#071524] p-4  w-full block">
        <h1 className="text-2xl font-bold font-baskerville mb-4">
          Privacy Policy
        </h1>
        <p className="mb-4">
          <strong>Effective Date:</strong> 1Sep 2024
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">1. Introduction</h2>
        <p className="mb-4">
          Welcome to Invitecard.me ("we", "our", "us"). We are committed to
          protecting your privacy and ensuring that your personal information is
          handled in a safe and responsible manner. This Privacy Policy outlines
          how we collect, use, and protect your information when you visit our
          website and use our services.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">
          2. Information We Collect
        </h2>
        <h3 className="text-lg font-medium mt-2 mb-1">
          2.1 Personal Information:
        </h3>
        <p className="mb-4">
          When you use our services, we may collect personal information that
          you provide to us, such as:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Billing and shipping address</li>
          <li>Payment information</li>
        </ul>
        <h3 className="text-lg font-medium mt-2 mb-1">
          2.2 Non-Personal Information:
        </h3>
        <p className="mb-4">
          We also collect non-personal information automatically as you navigate
          through our website. This may include:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>IP address</li>
          <li>Browser type</li>
          <li>Operating system</li>
          <li>Referring/exit pages</li>
          <li>Date and time of your visit</li>
          <li>Clickstream data</li>
        </ul>

        <h2 className="text-xl font-medium mt-4 mb-2">
          3. How We Use Your Information
        </h2>
        <p className="mb-4">
          We use the information we collect for the following purposes:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>To process and fulfill your orders</li>
          <li>To send you order confirmations and updates</li>
          <li>
            To respond to your customer service requests and support needs
          </li>
          <li>To personalize your experience and improve our website</li>
          <li>
            To send periodic emails regarding your order or other products and
            services
          </li>
          <li>
            To conduct analysis and gather information to improve our services
          </li>
        </ul>

        <h2 className="text-xl font-medium mt-4 mb-2">
          4. Information Sharing and Disclosure
        </h2>
        <p className="mb-4">
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except in the following circumstances:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            <strong>Service Providers:</strong> We may share your information
            with trusted third-party service providers who assist us in
            operating our website, conducting our business, or servicing you, as
            long as they agree to keep this information confidential.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your
            information when required by law or to protect our or others'
            rights, property, or safety.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your
            information may be transferred to the acquiring entity.
          </li>
        </ul>

        <h2 className="text-xl font-medium mt-4 mb-2">5. Data Security</h2>
        <p className="mb-4">
          We implement a variety of security measures to maintain the safety of
          your personal information. However, no method of transmission over the
          Internet or electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your personal information, we
          cannot guarantee its absolute security.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">
          6. Cookies and Tracking Technologies
        </h2>
        <p className="mb-4">
          Our website uses cookies and similar tracking technologies to enhance
          your experience, analyze site traffic, and understand user behavior.
          You can choose to accept or decline cookies through your browser
          settings. Please note that disabling cookies may affect your ability
          to use certain features of our website.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">7. Third-Party Links</h2>
        <p className="mb-4">
          Our website may contain links to third-party websites. These websites
          have their own privacy policies, and we do not assume any
          responsibility or liability for their content and activities. We
          encourage you to review the privacy policies of these third-party
          sites before providing any personal information.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">8. Children's Privacy</h2>
        <p className="mb-4">
          Our services are not directed to individuals under the age of 13. We
          do not knowingly collect personal information from children under 13.
          If we become aware that we have inadvertently received personal
          information from a child under 13, we will delete such information
          from our records.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">9. Your Rights</h2>
        <p className="mb-4">
          You have the right to access, update, or delete your personal
          information. You may also object to or restrict the processing of your
          personal information. To exercise these rights, please contact us at
          [support email].
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">
          10. Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We reserve the right to update or change this Privacy Policy at any
          time. Any changes will be posted on this page with an updated
          effective date. We encourage you to review this Privacy Policy
          periodically to stay informed about how we are protecting your
          information.
        </p>

        <h2 className="text-xl font-medium mt-4 mb-2">11. Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at:
        </p>
        <p>
          Email:{" "}
          <a href="mailto:info@invitecard.me" className="text-blue-500">
            info@invitecard.me
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
