import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { IoMdClose } from "react-icons/io";
import { banner_list1 } from "../utils/data";
import myContext from "../state/myContext";

const HomeSlider = ({ onClick }) => {
  const { banner_list } = useContext(myContext);
  const [active, setActive] = useState(0);

  return (
    <div className="relative flex justify-center">
      <Swiper
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="swiper"
        onActiveIndexChange={(index) => setActive(index.activeIndex)}
      >
        {banner_list?.list?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="h-auto pb-[100px] w-full flex">
              <img
                src={item.signed_url}
                className="h-auto w-full object-contain"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <IoMdClose
        size={30}
        color="#071524"
        onClick={onClick}
        className="absolute flex z-10 top-2 md:top-[20px] right-2 md:right-[20px] cursor-pointer"
      />
      <div className="absolute flex z-10 bottom-[2rem]">
        <button
          // onClick={() => window.open(banner_list?.list[active].link)}
          onClick={() => {
            onClick();
          }}
          className={`bg-[#005759] h-clamp-slider-button rounded-[5px] md:rounded-[10px] items-center px-clamp-x-slider-button text-clamp-base18 text-[#fff] font-inter font-medium`}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default HomeSlider;
