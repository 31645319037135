import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { openNotificationWithIcon } from "../../../App";
import { isTokenValid } from "../../../utils/utils";
import myContext from "../../../state/myContext";
import { Button, Modal } from "antd";
import Cropper from "react-easy-crop";
import Slider from "rc-slider";
import "rc-slider/assets/index.css"; // Import default styles

const CoverEdit = ({
  jsonData = {},
  sections,
  setSections,
  imageBoxes,
  setImageBoxes,
}) => {
  console.log(jsonData);

  const [croppingImage, setCroppingImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [img, setimg] = useState(null);
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);
  const [focusColor, setFocusColor] = useState("");
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [index, setIndex] = useState(null);
  const { setShow_login_modal } = useContext(myContext);

  const backgroundBlinkInterval = 500; // Blinking interval for background (milliseconds)

  const updateJsonWithPlaceholders = (jsonData) => {
    if (!jsonData) {
      return null; // Return null if jsonData or sections are missing
    }
    // Map through sections and update text to placeholder if sectionType is "text" and text is empty
    const updatedSections = jsonData
      .filter((section) => section.sectionType == "text")
      .map((section) =>
        section.placeholder
          ? { ...section }
          : {
              ...section,
              text: "", // Use placeholder text or empty string if placeholder is not provided
              placeholder: section.text, // Set placeholder to same value if not provided
            }
      );

    return updatedSections;
  };

  useEffect(() => {
    // Filter text sections
    setSections(updateJsonWithPlaceholders(jsonData?.sections));
    const newImageBoxes = jsonData?.sections?.filter(
      (section) => section.sectionType === "image"
    );
    setImageBoxes(newImageBoxes);
  }, [jsonData]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext("2d");
    const originalWidth = 1080;
    const originalHeight = 1920;
    const aspectRatio = originalWidth / originalHeight;

    const resizeCanvas = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let newWidth, newHeight;

      // if (windowWidth / windowHeight > aspectRatio) {
      //   newHeight = windowHeight - 200;
      //   newWidth = newHeight * aspectRatio;
      // } else {
      //   newWidth = windowWidth - 100;
      //   newHeight = newWidth / aspectRatio;
      // }
      if (windowWidth / windowHeight > aspectRatio) {
        newHeight = windowHeight - 200;
        newWidth = newHeight * aspectRatio;
      } else {
        newWidth = windowWidth - 40;
        newHeight = newWidth / aspectRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
    };

    const renderVideoOnCanvas = () => {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      requestAnimationFrame(renderVideoOnCanvas);
    };

    // Resize the canvas to fit the screen
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    // Autoplay the video and start rendering it on the canvas
    video.addEventListener("loadeddata", () => {
      video.play();
      renderVideoOnCanvas();
    });

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, [jsonData]);

  const calculateTextWidth = (text, font) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (!ctx) {
      console.error("Failed to get canvas context");
      return 0;
    }

    ctx.font = font;
    return ctx.measureText(text).width;
  };
  const getPositionStyle = (section, index) => {
    if (!canvasRef.current) return {};
    const fontFace = new FontFace(section.fontName, `url(${section.fontFile})`);
    fontFace
      .load()
      .then(() => {
        document.fonts.add(fontFace);
        document.body.style.fontFamily = `${section.fontName}, sans-serif`;
      })
      .catch((err) => {
        console.error("Failed to load font:", err);
      });
    const originalWidth = 1080;
    const originalHeight = 1920;
    const aspectRatio = originalWidth / originalHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let newWidth, newHeight;

    if (windowWidth / windowHeight > aspectRatio) {
      newHeight = windowHeight - 320;
      newWidth = newHeight * aspectRatio;
    } else {
      newWidth = windowWidth - 150;
      newHeight = newWidth / aspectRatio;
    }

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const fontSize = (newWidth * section.fontSize) / 1080;

    // Calculate text width, considering the placeholder if text is empty
    const textWidth = calculateTextWidth(
      section.text?.length > 0 ? section.text : section?.placeholder,
      `${fontSize}px '${section.fontName}'`
    );

    const buffer = 25; // Buffer for padding and border
    const maxTextWidth = canvasRect.width - buffer; // Maximum text width

    // If text width exceeds canvas width, set it to the maximum text width
    const finalTextWidth =
      textWidth + buffer > maxTextWidth ? maxTextWidth : textWidth + buffer;

    // Calculate dynamic position based on canvas size
    const dynamicLeft = (canvasRect.width - finalTextWidth) / 2;
    const dynamicTop = (canvasRect.height * section.placementY) / 1920;

    return {
      position: "absolute",
      left: `${dynamicLeft}px`,
      top: `${dynamicTop}px`,
      width: `${finalTextWidth}px`, // Adjust width based on condition
      fontSize: `${fontSize}px`,
      textAlign: "center", // Center text horizontally
      color: section.fontColor,
      backgroundColor: focusedIndex === index ? backgroundColor : "transparent", // Change background color if focused
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: section.fontColor,
      outline: "none",
      zIndex: 1,
      fontFamily: `${section.fontName}, sans-serif`,
      whiteSpace: "nowrap", // Ensure text doesn't wrap
      overflow: textWidth + buffer > maxTextWidth ? "hidden" : "visible", // Handle overflow
      textOverflow: textWidth + buffer > maxTextWidth ? "ellipsis" : "clip", // Add ellipsis if text overflows
    };
  };
  const getImageStyle = (section, index) => {
    if (!canvasRef.current) return {};

    const originalWidth = 1080;
    const originalHeight = 1920;
    const aspectRatio = originalWidth / originalHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let newWidth, newHeight;

    if (windowWidth / windowHeight > aspectRatio) {
      newHeight = windowHeight - 200;
      newWidth = newHeight * aspectRatio;
    } else {
      newWidth = windowWidth - 100;
      newHeight = newWidth / aspectRatio;
    }

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const finalWidth = (newWidth * section.width) / 1080;
    const finalHeight = (newHeight * section.height) / 1920;

    // Calculate dynamic position based on canvas size
    let newWidth1;
    if (windowWidth / windowHeight > aspectRatio) {
      newWidth1 = newWidth;
    } else {
      newWidth1 = newWidth + 60;
    }

    const dynamicLeft = (newWidth1 - finalWidth) / 2;
    const dynamicTop = (canvasRect.height * section.placementY) / 1920;

    return {
      position: "absolute",
      left: `${dynamicLeft}px`,
      top: `${dynamicTop}px`,
      height: `${finalWidth}px`,
      width: `${finalHeight}px`,
      borderRadius: section.radius,
    };
  };

  const handleInputChange = (index, event) => {
    const newSections = [...sections];
    newSections[index].text = event.target.value;
    setSections(newSections);
  };

  // Handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCroppingImage(imageUrl); // Store the uploaded image URL in state
      setIsModalVisible(true); // Open the modal after uploading the image
    }
  };

  // Crop completion handler
  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // Get cropped image
  const getCroppedImage = async () => {
    if (!croppingImage || !croppedAreaPixels) return;

    const image = new Image();
    image.src = croppingImage;
    await image.decode();

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    onSubmit(base64Image); // Pass the base64 data to onSubmit
    setIsModalVisible(false); // Close the modal after cropping
    URL.revokeObjectURL(croppingImage); // Clean up memory
  };
  const user = JSON.parse(localStorage.getItem("token"));

  const onSubmit = (base64) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL + "post-user-file",
      headers: {
        Auth_ID_Token: user.IdToken,
        Access_Token: user.AccessToken,
        File_Name: "test.txt",
        "Content-Type": "application/json",
      },
      data: base64,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(index, imageBoxes);
        if (response.status === 200 && response.data.statusCode === 200) {
          const updatedImageBoxes = imageBoxes.map((box, idx) => {
            if (idx === index) {
              return { ...box, imageSrc: response.data.file_url }; // Create a new object with the updated imageSrc
            }
            return box; // Return the original object if not the one we're updating
          });

          setImageBoxes(updatedImageBoxes);
          setIndex(null);
        }
      })
      .catch((error) => {
        openNotificationWithIcon("error", "Error", error.message);
      });
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    let backgroundBlinkIntervalId;

    if (focusedIndex !== null) {
      backgroundBlinkIntervalId = setInterval(() => {
        setBackgroundColor((prevColor) =>
          prevColor === focusColor ? "transparent" : focusColor
        );
      }, backgroundBlinkInterval);
    } else {
      setBackgroundColor("transparent"); // Reset background color when not focused
    }

    return () => {
      clearInterval(backgroundBlinkIntervalId);
    };
  }, [focusedIndex]);

  const handleModalClose = () => {
    // Clear the cropping image and close the modal
    if (croppingImage) {
      URL.revokeObjectURL(croppingImage); // Clean up the object URL
    }
    setCroppingImage(null);
    setIsModalVisible(false);
  };
  return (
    <div style={{ position: "relative" }}>
      <video
        ref={videoRef}
        src={jsonData.pageVideo}
        style={{ display: "none" }}
        muted
        autoPlay
        loop
      />
      <canvas
        ref={canvasRef}
        style={{ borderRadius: 10, position: "relative" }}
      />
      <input
        ref={fileInputRef}
        id="select"
        type="file"
        style={{ display: "none" }}
        onChange={(event) => handleImageUpload(event)}
      />
      {imageBoxes?.map((section, index) => (
        <img
          id="select"
          key={index}
          src={section.imageSrc}
          style={getImageStyle(section)}
          alt={`Image section ${index}`}
          onClick={() => {
            setIndex(index);
            triggerFileUpload(index);
          }}
        />
      ))}

      {sections?.map((section, index) => (
        <input
          key={index}
          type="text"
          value={section.text}
          placeholder={section.placeholder}
          onChange={(e) => handleInputChange(index, e)}
          onFocus={() => {
            setFocusColor(section.fontColor + "50");
            setFocusedIndex(index);
          }}
          onBlur={() => setFocusedIndex(null)}
          className={`placeholder-[${section.fontColor}]`}
          style={{
            ...getPositionStyle(section, index),
          }}
        />
      ))}
      <Modal
        title="Crop Image"
        open={isModalVisible}
        onCancel={() => handleModalClose()}
        footer={[
          <Button key="cancel" onClick={() => handleModalClose()}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={() => {
              getCroppedImage();
              handleModalClose();
            }}
          >
            Save
          </Button>,
        ]}
        centered
      >
        {croppingImage && (
          <div className="crop-container relative w-full h-64">
            <Cropper
              image={croppingImage}
              crop={crop}
              zoom={zoom}
              aspect={4 / 4}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        )}
        <div className="flex flex-col mt-4 max-w-[300px]">
          <p className="font-inter text-[#071524] text-sm font-medium mt-4 mb-2">
            Zoom
          </p>

          <Slider
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(value) => setZoom(value)}
            trackStyle={{ backgroundColor: "#103935" }} // Track color
            handleStyle={{
              color: "#103935",
              backgroundColor: "#103935",
              borderColor: "#103935", // Handle border color
            }}
            // railStyle={{ backgroundColor: "#cbd5e1", height: 8 }} // Rail color
          />
        </div>
      </Modal>
    </div>
  );
};

export default CoverEdit;
