import React from "react";
import { TitleText } from "../utils/layoutUtils";

const FeaturesCard = ({ item }) => {
  return (
    <div className="w-full  items-center  flex flex-col p-clamp-md border-[1px] border-[#0715241A] rounded-md md:rounded-[20px] shadow-xl shadow-[#91B4E426]">
      <div
        className={`bg-gradient-to-br from-[#FFF090] to-[#FABF55] shadow-2xl shadow-[#91B4E426]  border-[1px] border-[#CD8E42] h-clamp-md w-clamp-md rounded-full flex items-center text-[14px] text-[#071524] font-inter font-normal justify-center `}
      >
        <img
          className="h-[60%] w-[60%] object-contain"
          src={item?.icon}
          alt="heart icon"
        />
      </div>
      {/* <img
        className="h-[19%] w-[19%] object-contain"
        src={item?.icon}
        alt="heart icon"
      /> */}
      <div
        className={`font-inter font-medium text-custom-text text-center text-clamp-base20 my-2 md:my-4`}
      >
        {item.title}
      </div>
      <div
        className={`font-inter font-normal text-custom-text text-center text-[14px]  `}
      >
        {item.description}
      </div>
    </div>
  );
};

export default FeaturesCard;
