import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="max-w-[90%] md:max-w-[80%] w-full  mx-auto flex pt-4">
      <div className="font-inter text-[#071524]">
        <h1 className="text-2xl font-bold mb-4 font-baskerville">
          Terms and Conditions
        </h1>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">Introduction</h2>
          <p>
            Welcome to Invitecard.me. These Terms and Conditions ("Terms")
            govern your use of our website and services. By accessing or using
            our services, you agree to be bound by these Terms. If you do not
            agree with these Terms, please do not use our services.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            1. Use of Our Services
          </h2>

          <h3 className="font-medium font-inter mt-2">Eligibility:</h3>
          <p>
            You must be at least 18 years old to use our services. By using our
            services, you represent and warrant that you meet this age
            requirement.
          </p>

          <h3 className="font-medium font-inter mt-2">Account Registration:</h3>
          <p>
            To access features of our website, you will have to register for an
            account. You agree to provide accurate, current, and complete
            information during the registration process and to update such
            information as necessary. You are responsible for safeguarding your
            account password and for all activities that occur under your
            account.
          </p>

          <h3 className="font-medium font-inter mt-2">Permitted Use:</h3>
          <p>
            You agree to use our services only for lawful purposes and in
            accordance with these Terms. You agree not to use our services:
          </p>
          <ul className="list-disc ml-5">
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content or otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate Invitecard.me, a
              Invitecard.me employee, another user, or any other person or
              entity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the services, or which, as determined by us,
              may harm Invitecard.me or users of the services or expose them to
              liability.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            2. User Content
          </h2>

          <h3 className="font-medium font-inter mt-2">Content Ownership:</h3>
          <p>
            You retain ownership of all content you submit, post, or display on
            or through our services ("User Content"). However, by submitting
            User Content, you grant Invitecard.me a worldwide, non-exclusive,
            royalty-free, fully paid, transferable, sublicensable license to
            use, reproduce, distribute, prepare derivative works of, display,
            and perform the User Content in connection with the services and
            Invitecard.me's business, including without limitation for promoting
            and redistributing part or all of the services (and derivative works
            thereof) in any media formats and through any media channels.
          </p>

          <h3 className="font-medium font-inter mt-2">Content Standards:</h3>
          <p>
            User Content must comply with these Terms and all applicable laws
            and regulations. Without limiting the foregoing, User Content must
            not:
          </p>
          <ul className="list-disc ml-5">
            <li>
              Contain any material that is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable.
            </li>
            <li>
              Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age.
            </li>
            <li>
              Infringe any patent, trademark, trade secret, copyright, or other
              intellectual property or other rights of any other person.
            </li>
            <li>
              Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              and our Privacy Policy.
            </li>
            <li>Be likely to deceive any person.</li>
            <li>
              Promote any illegal activity, or advocate, promote, or assist any
              unlawful act.
            </li>
            <li>
              Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, alarm, or annoy any other person.
            </li>
            <li>
              Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization.
            </li>
            <li>
              Involve commercial activities or sales, such as contests,
              sweepstakes, and other sales promotions, barter, or advertising.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            3. Intellectual Property Rights
          </h2>

          <h3 className="font-medium font-inter mt-2">Ownership:</h3>
          <p>
            The services and their entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by Invitecard.me and its licensors.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            Intellectual Property
          </h2>

          <h3 className="font-medium font-inter mt-2">Ownership:</h3>
          <p>
            All content on the Site, including but not limited to text,
            graphics, logos, images, audio clips, video clips, data
            compilations, and software, is the property of Invitecard.me or its
            content suppliers and is protected by international copyright,
            trademark, patent, trade secret, and other intellectual property or
            proprietary rights laws.
          </p>

          <h3 className="font-medium font-inter mt-2">Trademarks:</h3>
          <p>
            The trademarks, logos, service marks, and trade names displayed on
            the Site are registered and unregistered trademarks of Invitecard.me
            or third parties and may not be used without prior written
            permission from the respective trademark owner.
          </p>

          <h3 className="font-medium font-inter mt-2">
            License and Site Access:
          </h3>
          <p>
            Invitecard.me grants you a limited, non-exclusive, non-transferable,
            and revocable license to access and make personal, non-commercial
            use of the Site. This license does not include any resale or
            commercial use of the Site or its contents; any collection and use
            of any product listings, descriptions, or prices; any derivative use
            of the Site or its contents; any downloading or copying of account
            information for the benefit of another merchant; or any use of data
            mining, robots, or similar data gathering and extraction tools.
          </p>

          <h3 className="font-medium font-inter mt-2">Restrictions:</h3>
          <p>You may not:</p>
          <ul className="list-disc ml-5">
            <li>
              Reproduce, duplicate, copy, sell, resell, visit, or otherwise
              exploit the Site or any portion of it for any commercial purpose
              without the express written consent of Invitecard.me.
            </li>
            <li>
              Frame or utilize framing techniques to enclose any trademark,
              logo, or other proprietary information (including images, text,
              page layout, or form) of Invitecard.me without express written
              consent.
            </li>
            <li>
              Use any meta tags or any other "hidden text" utilizing
              Invitecard.me's name or trademarks without the express written
              consent of Invitecard.me.
            </li>
            <li>Misuse the Site or the materials provided on the Site.</li>
          </ul>

          <h3 className="font-medium font-inter mt-2">User Contributions:</h3>
          <p>
            By submitting any User Content (e.g., text, images, audio, video) to
            the Site, you grant Invitecard.me a worldwide, non-exclusive,
            royalty-free, fully paid, transferable, sublicensable license to
            use, reproduce, modify, adapt, publish, translate, create derivative
            works from, distribute, perform, and display such User Content in
            connection with the Site and Invitecard.me's business, including
            without limitation for promoting and redistributing part or all of
            the Site in any media formats and through any media channels.
          </p>

          <h3 className="font-medium font-inter mt-2">
            Reporting Intellectual Property Infringements:
          </h3>
          <p>
            If you believe that any content on the Site infringes your
            intellectual property rights, please contact us at
            info@invitecard.me with a detailed description of the alleged
            infringement. We will investigate and take appropriate action, which
            may include removing the infringing content.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            Services and Products
          </h2>

          <h3 className="font-medium font-inter mt-2">Overview:</h3>
          <p>
            Invitecard.me offers a variety of digital invitation creation
            services and products, including but not limited to personalized
            video invitations and card PDFs for various occasions such as
            weddings, engagements, birthdays, Valentine's Week, and all major
            Hindu festivals.
          </p>

          <h3 className="font-medium font-inter mt-2">Product Descriptions:</h3>
          <p>
            We strive to ensure that our product descriptions are as accurate as
            possible. However, we do not warrant that the product descriptions
            or other content on the Site are accurate, complete, reliable,
            current, or error-free. If a product offered by Invitecard.me is not
            as described, your sole remedy is to discontinue the use of our
            services.
          </p>

          <h3 className="font-medium font-inter mt-2">Customization:</h3>
          <p>
            Users can customize their invitations by entering specific details
            such as names, dates, times, and locations. However, customization
            options are limited to text input only. To maintain the aesthetic
            quality of our designs, users cannot alter the text color or
            placement.
          </p>

          <h3 className="font-medium font-inter mt-2">Music Addition:</h3>
          <p>
            For video invitations, users can add background music. Users may
            choose from our library of music or upload their own music files.
            Please ensure you have the necessary rights or permissions to use
            any uploaded music.
          </p>

          <h3 className="font-medium font-inter mt-2">
            Creation and Download:
          </h3>
          <p>
            Once the customization process is complete, users can generate both
            video invitations and card PDFs simultaneously without needing to
            re-enter details. After generation, users can download the
            high-quality invitations directly from the Site.
          </p>

          <h3 className="font-medium font-inter mt-2">Pricing and Payments:</h3>
          <p>
            Pricing for our services and products is clearly listed on the Site.
            We offer both free and premium templates. Payments are processed
            through secure payment gateways. All transactions are final, and we
            do not offer refunds unless there is a technical issue or defect
            with the final product.
          </p>

          <h3 className="font-medium font-inter mt-2">Technical Support:</h3>
          <p>
            If you encounter any issues while using our services, you can reach
            our customer support team via [support email] or [phone number]. Our
            support team is available to assist you with any technical
            difficulties or questions you may have.
          </p>

          <h3 className="font-medium font-inter mt-2">Limitations:</h3>
          <p>
            Our services are provided "as is" and "as available" without any
            warranties, express or implied. Invitecard.me does not guarantee
            that our services will be uninterrupted, error-free, or free from
            viruses or other harmful components.
          </p>

          <h3 className="font-medium font-inter mt-2">
            User Responsibilities:
          </h3>
          <p>
            Users are responsible for ensuring that the content they upload and
            the details they provide do not violate any laws or infringe on the
            rights of third parties. Users agree not to use our services for any
            unlawful purposes or in ways that could damage, disable, overburden,
            or impair the Site.
          </p>

          <h3 className="font-medium font-inter mt-2">
            Modifications to Services:
          </h3>
          <p>
            Invitecard.me reserves the right to modify or discontinue,
            temporarily or permanently, any service or product offered on the
            Site, with or without notice. You agree that Invitecard.me will not
            be liable to you or to any third party for any modification,
            suspension, or discontinuation of any service or product.
          </p>

          <h3 className="font-medium font-inter mt-2">Refund Policy:</h3>
          <p>
            We strive to ensure that our users are satisfied with our services.
            If there is a technical issue or defect with the final product,
            please contact our support team within [specific time frame, e.g., 7
            days] of purchase to request a refund or adjustment.
          </p>

          <h3 className="font-medium font-inter mt-2">Disclaimers:</h3>
          <p>
            All products and services are provided on an "as is" basis without
            any warranties of any kind, either express or implied, including,
            but not limited to, implied warranties of merchantability, fitness
            for a particular purpose, and non-infringement. Invitecard.me makes
            no warranty that (a) the services will meet your requirements; (b)
            the services will be uninterrupted, timely, secure, or error-free;
            (c) the results that may be obtained from the use of the services
            will be accurate or reliable; or (d) any errors in the services will
            be corrected.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            Acceptance of Terms
          </h2>
          <p>
            By accessing and using Invitecard.me ("the Site") and our services,
            you agree to comply with and be bound by these Terms and Conditions
            ("Terms"). If you do not agree to these Terms, you should not use
            the Site or our services. These Terms may be updated from time to
            time without notice to you. Your continued use of the Site after any
            such changes constitutes your acceptance of the new Terms.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">
            Changes to Terms
          </h2>
          <p>
            Invitecard.me reserves the right to modify or update these Terms at
            any time without prior notice. It is your responsibility to review
            these Terms periodically for any changes. Your continued use of the
            Site and our services following the posting of any changes to these
            Terms constitutes acceptance of those changes. If you do not agree
            to the updated Terms, you must stop using the Site and our services.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">Termination</h2>
          <p>
            We reserve the right to terminate or suspend your account and access
            to the Site at our sole discretion, without notice, for conduct that
            we believe violates these Terms or is harmful to other users of the
            Site, us, or third parties, or for any other reason.
          </p>
        </section>
        {/* New Disclaimer Section */}
        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">Disclaimer</h2>

          <h3 className="font-medium font-inter mt-2">General Information:</h3>
          <p>
            The information provided on invitecard.me is for general
            informational purposes only. While we strive to keep the information
            up-to-date and correct, we make no representations or warranties of
            any kind, express or implied, about the completeness, accuracy,
            reliability, suitability, or availability with respect to the Site
            or the information, products, services, or related graphics
            contained on the Site for any purpose. Any reliance you place on
            such information is therefore strictly at your own risk.
          </p>

          <h3 className="font-medium font-inter mt-2">
            No Professional Advice:
          </h3>
          <p>
            The content on the Site, including but not limited to text,
            graphics, images, and other material, is provided for informational
            purposes only and is not intended to be a substitute for
            professional advice. Always seek the advice of a qualified
            professional with any questions you may have regarding a specific
            service or product.
          </p>

          <h3 className="font-medium font-inter mt-2">Service Availability:</h3>
          <p>
            Invitecard.me does not guarantee that the Site will be available at
            all times or that access will be uninterrupted, timely, secure, or
            error-free. We reserve the right to suspend or withdraw all or part
            of the Site without notice for any reason.
          </p>

          <h3 className="font-medium font-inter mt-2">
            Limitation of Liability:
          </h3>
          <p>
            In no event will Invitecard.me, its affiliates, licensors, service
            providers, employees, agents, officers, or directors be liable for
            any indirect, incidental, special, consequential, or punitive
            damages, including but not limited to loss of profits, data, use,
            goodwill, or other intangible losses, resulting from:
          </p>
          <ul className="list-disc ml-5">
            <li>Your use or inability to use the Site or our services;</li>
            <li>
              Any unauthorized access to or use of our servers and/or any
              personal information stored therein;
            </li>
            <li>
              Any interruption or cessation of transmission to or from the Site;
            </li>
            <li>
              Any bugs, viruses, trojan horses, or the like that may be
              transmitted to or through our Site by any third party;
            </li>
            <li>
              Any errors or omissions in any content or for any loss or damage
              incurred as a result of the use of any content posted, emailed,
              transmitted, or otherwise made available through the Site;
            </li>
            <li>
              Any other matter relating to the Site or our services, whether
              based on warranty, contract, tort (including negligence), or any
              other legal theory, whether or not Invitecard.me has been informed
              of the possibility of such damage.
            </li>
          </ul>

          <h3 className="font-medium font-inter mt-2">Aggregate Liability:</h3>
          <p>
            Notwithstanding anything to the contrary contained herein,
            Invitecard.me's liability to you for any cause whatsoever and
            regardless of the form of the action, will at all times be limited
            to the amount paid, if any, by you to Invitecard.me for the services
            during the twelve (12) months prior to the event giving rise to
            liability.
          </p>

          <h3 className="font-medium font-inter mt-2">Exclusions:</h3>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of liability for incidental or
            consequential damages. Accordingly, some of the above limitations
            may not apply to you. In such jurisdictions, Invitecard.me's
            liability will be limited to the greatest extent permitted by law.
          </p>

          <h3 className="font-medium font-inter mt-2">Assumption of Risk:</h3>
          <p>
            You use the Site and our services at your own risk. The Site and our
            services are provided on an "as is" and "as available" basis.
            Invitecard.me disclaims all warranties, whether express or implied,
            including any warranties of merchantability, fitness for a
            particular purpose, non-infringement, and title. Invitecard.me does
            not warrant that the Site or our services will meet your
            requirements, be uninterrupted, timely, secure, or error-free, that
            the information provided through the Site is accurate, reliable, or
            correct, or that any defects or errors will be corrected.
          </p>

          <h3 className="font-medium font-inter mt-2">
            User Responsibilities:
          </h3>
          <p>
            It is your responsibility to ensure that your use of the Site and
            our services complies with all applicable laws and regulations. You
            are solely responsible for the accuracy and appropriateness of all
            data and content that you submit to the Site.
          </p>

          <h3 className="font-medium font-inter mt-2">Contact Information:</h3>
          <p>
            If you have any questions about these Terms or our services, please
            contact us at:
          </p>
          <p>Email: info@invitecard.me</p>
          <p>
            By using our Site and services, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions.
          </p>
        </section>

        {/* New Governing Law Section */}
        <section className="mb-4">
          <h2 className="text-xl font-medium font-inter mb-2">Governing Law</h2>

          <h3 className="font-medium font-inter mt-2">Governing Law:</h3>
          <p>
            These Terms and Conditions and any separate agreements whereby we
            provide you services shall be governed by and construed in
            accordance with the laws of MP/India, without regard to its conflict
            of law principles.
          </p>

          <h3 className="font-medium font-inter mt-2">Jurisdiction:</h3>
          <p>
            You agree that any dispute or claim arising out of or relating to
            these Terms and Conditions, your use of the Site, or our services
            will be subject to the exclusive jurisdiction of the courts located
            within MP/India. You hereby waive any objection to the jurisdiction
            and venue of such courts.
          </p>

          <h3 className="font-medium font-inter mt-2">Legal Proceedings:</h3>
          <p>
            In the event of any controversy or dispute between Invitecard.me and
            you arising out of or in connection with your use of the Site, the
            parties shall attempt, promptly and in good faith, to resolve any
            such dispute. If we are unable to resolve any such dispute within a
            reasonable time (not to exceed thirty (30) days), then either party
            may submit such controversy or dispute to mediation. If the dispute
            cannot be resolved through mediation, then the parties shall be free
            to pursue any right or remedy available to them under applicable
            law.
          </p>

          <h3 className="font-medium font-inter mt-2">Compliance with Laws:</h3>
          <p>
            You are responsible for compliance with all applicable laws,
            regulations, and ordinances related to your use of the Site and our
            services. Your use of the Site may also be subject to other local,
            state, national, or international laws.
          </p>

          <h3 className="font-medium font-inter mt-2">Severability:</h3>
          <p>
            If any provision of these Terms is found by a court of competent
            jurisdiction to be invalid, illegal, or unenforceable, the remaining
            provisions of these Terms will remain in full force and effect.
          </p>

          <h3 className="font-medium font-inter mt-2">Waiver:</h3>
          <p>
            No waiver of any term of these Terms shall be deemed a further or
            continuing waiver of such term or any other term, and
            Invitecard.me's failure to assert any right or provision under these
            Terms shall not constitute a waiver of such right or provision.
          </p>

          <p>
            By using our Site and services, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
